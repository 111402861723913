<template>
    <div :class="param" class="py-4">
        <div class="form-group mb-0 row">
            <div class="offset-md-3 col-md-9">
                <h4 class="fw-normal">{{ question }}</h4>
            </div>
            <label
                class="col-md-3 col-form-label col-form-label-lg text-md-right pl-md-0"
                >{{ label }}</label
            >
            <div class="col-md-9">
                <!-- collection -->
                <p
                    v-for="(item, index) in collection"
                    :key="'item-' + index"
                    class="mb-2 position-relative d-block entry"
                >
					<textarea 
                        :index="index"
						class="border rounded bg-light textarea item" 
						v-model="collection[index]"
						v-on:keyup="onKeyUp($event)"
						v-on:keydown="onKeyDown($event)"
                        v-on:focus="resize($event.currentTarget)"
					></textarea>
                    <!-- item text will be injected here -->
                    <a
                        href="#"
                        v-show="collection.length > 1"
                        @click="removeItem(index, $event)"
                        class="pull-right position-absolute btn-remove"
                        tabindex="-1"
                    >
                        <material-icons icon="remove-entry-icon"></material-icons>
                    </a>
                </p>
            </div>
            <div class="offset-md-3 col-md-9">
                <p class="pl-md-2 text-muted">{{ hint }}</p>
                <a
                    href="#"
                    @click="addItem($event)"
                    class="btn btn-outline-primary btn-lg btn-add"
                >
                    {{btnAddLabel || 'Add Item'}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import MaterialIcons from "@/components/icons/MaterialIcons.vue";


export default {
    name: "InputList",
    components: {
        MaterialIcons
    },
    props: ["question", "placeholder", "label", "hint", "param", "value", "btnAddLabel"],
    data: function() {
        return {
            collection: [],
            metaKey: false,
        };
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.update(this.param, newValue);
            },
        },
    },

    watch: {
        val(newValue) {
			if(typeof newValue != 'undefined' && newValue.length > 0) {
				this.collection = newValue.split('|');
			} else {
				this.collection = [''];
            }
            
            setTimeout(() => {
                this.resizeAll();  
            }, 0);
        }
    },



    methods: {
        onKeyDown(event) {
            let code = event.code;
            let textarea = event.currentTarget;
            let index = parseInt( textarea.getAttribute('index') );

            if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(code) ) {
                return;
            }


            if (code == "MetaLeft") {
                this.metaKey = true;
            }

            /**
             * TODO: get cmd+Delete to work
             * 
             */
            if (code == "Backspace" && this.metaKey === true) {
                if(this.collection.length > 1) {
                    this.removeItem(index, event);
                    event.preventDefault();
                    setTimeout(() => {
                        let next = index > this.collection.length - 1? index - 1 : index;
                        // alert(index + ' > ' + next)
                        this.focusOn(next);
                    }, 0);
                    return;
                } else {
                    return;
                }
            }



            if (code == "Enter" && this.metaKey === true) {
                textarea.value = textarea.value +'\n';

            } else if(code == "Enter") {

                if(index === this.collection.length - 1) {
                    // add item if user presses ENTER while focus on the last entry
                    this.addItem(event);
                } else {
                    // go to the next entry
                    event.preventDefault();
                    this.focusOn(index + 1);
                }
            }

            this.resize(textarea);
        },


        onKeyUp(event) {
            let code = event.code;
            let textarea = event.currentTarget;
            // let index = parseInt( textarea.getAttribute('index') );

            if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(code) ) {
                return;
            }

            if (code == "MetaLeft") {
                this.metaKey = false;
            }

            this.update();
            this.resize(textarea);
        },


		update() {
			this.val = this.collection.join('|');
        },
        


        updateCollection(index, event) {
            let content = event.target.innerText;
            this.colletion[index] = content;
		},
		


		resize(textarea) {
            textarea.style.height = 'auto';
            let height = textarea.value.length > 35? (textarea.scrollHeight + 2 /** border width (top + bottom) */)  +'px' :  '48px';
            textarea.style.height = height;
        }, 

        resizeAll() {
            let entries = this.$el.querySelectorAll("textarea");

            entries.forEach((item) => {
                this.resize(item);   
            });
        },
        

        addItem(event) {
            event.preventDefault();
			this.collection.push("");
            setTimeout(() => {
                this.focusOn();
            }, 100);
		},
		



        removeItem(index, event) {
            event
            // event.preventDefault();
            if (this.collection.length <= 1) {
                return;
            }

            this.collection.splice(index, 1);
            this.update();
            setTimeout(() => {
                // let next = index === 0? 0 : index;
                //     next = index === this.collection.length - 1? index - 1 : next;
                let next = index > this.collection.length - 1? index - 1 : index;

                this.focusOn(next);
            }, 100);
        },

        focusOn(index) {
            let entries = this.$el.querySelectorAll(".item");
            index = typeof index == 'undefined'? entries.length - 1 : index;
            entries[index].focus();
        },
    },


    mounted: function() {
		setTimeout(()=>{
			let val = this.value;

            console.log(val);


			if(typeof val != 'undefined' && val.length > 0) {
				this.collection = val.split('|');
			} else {
				this.collection.push("");
            }
            
            setTimeout(() => {
                this.resizeAll();
            }, 0);

		}, 100);


		// window[this.param] = this;
    },
};
</script>

<style scoped>

.item + .btn-remove:hover,
.item:focus + .btn-remove {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}

.btn-add:focus {
    background-color: var(--primary);
    color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5) !important;
}
.btn-remove {
    opacity: 0;
    top: 0;
    right: 0;
    transition: all 0.05s;
    margin: 7px;
    transform: scale3d(0, 0, 0);
    transform-origin: center center;
}

textarea {

    /* top: 0;
    right: 0;
    left: 0;
    bottom: 0; */
    width: 100%;
    /* display: block; */



    overflow: hidden;
    /* height: auto; */

    min-height: 40px;
    line-height: 20px;
    resize: none;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    font-size: 1.25rem;
    color: #495057;
    line-height: 1.5;
    font-weight: normal;
}
</style>
