<template>
  <select 
    :id="param"
    :name="param"
    v-model="val"
    class="form-control form-control-lg bg-light">
    <option value=""></option>
    <option value="frustrated">frustrated</option>
    <option value="dissatisfied">dissatisfied</option>
    <option value="angry">angry</option>
    <option value="annoyed">annoyed</option>
    <option value="displeased">displeased</option>
    <option value="tired">tired</option>
    <option value="upset">upset</option>
    <option value="worried">worried</option>
    <option value="scared">scared</option>
    <option value="desperate">desperate</option>
    <option value="concern">concern</option>
    <option value="afraid">afraid</option>
    <option value="powerless">powerless</option>
    <option value="unhappy">unhappy</option>
    <option value="determined">determined</option>
    <option value="pasionate">pasionate</option>
    <option value="envious">envious</option>
    <option value="interested">interested</option>
    <option value="helpless">helpless</option>
    <option value="denial">denial</option>
    <option value="uncomfortable">uncomfortable</option>
    <option value="overwhelmed">overwhelmed</option>
  </select>
</template>

<script>
export default {
  name: "InputEmotion",
  props: ["param", "value"],
  data: function() {
    return {};
  },

  computed: {
    val: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$parent.progress = true;
        this.$parent.update(this.param, newValue);
      },
    },
  }
};
</script>
