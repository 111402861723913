<template>
    <section
        id="form-goals"
        class="form form-goals"
    >
        <div class="container">
            <form-header
                title="Goals"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                description="Capture your customers deepest desires and aspirations. 
                When they buy your solutions, what do they want to accomplish?"
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">
                        <form-subheader
                            title="Main Goal"
                            description=""
                        ></form-subheader>

                        <input-text
                            question="As a customer, once I find a solution, I want to be able to..."
                            placeholder=""
                            hint='State the main goal in general terms (avoid possesive pronouns like "your", "my", "their", "his", "hers", "its", "whose", etc). e.g. I want to be able to "automate the company&apos;s sales", "reduce turn around time by 50%".'
                            label="Main Goal"
                            param="main"
                            :value="formData['main']"
                        >
                        </input-text>

                        <form-subheader
                            title="Describe your audience aspirations"
                            description="What would they like to achieve?"
                        ></form-subheader>

                        <input-multiple
                            question="(as a customer) I would like to..."
                            hint=""
                            param="aspirations"
                        >
                            <input-text
                                placeholder=""
                                hint="...so I can..."
                                label="What You Want (1)"
                                param="aspiration_1"
                                :value="formData['aspiration_1']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            ></input-text>

                            <input-text
                                placeholder=""
                                hint='( e .g. "create a faster... reduce the time it takes to... " )'
                                label="Result (1)"
                                param="result_1"
                                :value="formData['result_1']"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            ></input-text>
                        </input-multiple>

                        <input-multiple
                            question="(as a customer) I would like to..."
                            hint=""
                            param="aspirations"
                        >
                            <input-text
                                placeholder=""
                                hint="...so I can..."
                                label="What You Want (2)"
                                param="aspiration_2"
                                :value="formData['aspiration_2']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            ></input-text>

                            <input-text
                                placeholder=""
                                hint='( e .g. "create a faster... reduce the time it takes to... " )'
                                label="Result (2)"
                                param="result_2"
                                :value="formData['result_2']"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            ></input-text>
                        </input-multiple>

                        <input-multiple
                            question="(as a customer) I would like to..."
                            hint=""
                            param="aspirations"
                        >
                            <input-text
                                placeholder=""
                                hint="...so I can..."
                                label="What You Want (3)"
                                param="aspiration_3"
                                :value="formData['aspiration_3']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            ></input-text>

                            <input-text
                                placeholder=""
                                hint='( e .g. "create a faster... reduce the time it takes to... " )'
                                label="Result (3)"
                                param="result_3"
                                :value="formData['result_3']"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            ></input-text>
                        </input-multiple>

                        <input-multiple
                            question="(as a customer) I would like to..."
                            hint=""
                            param="aspirations"
                        >
                            <input-text
                                placeholder=""
                                hint="...so I can..."
                                label="What You Want (4)"
                                param="aspiration_4"
                                :value="formData['aspiration_4']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            ></input-text>

                            <input-text
                                placeholder=""
                                hint='( e .g. "create a faster... reduce the time it takes to... " )'
                                label="Result (4)"
                                param="result_4"
                                :value="formData['result_4']"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            ></input-text>
                        </input-multiple>

                        <input-multiple
                            question="(as a customer) I would like to..."
                            hint=""
                            param="aspirations"
                        >
                            <input-text
                                placeholder=""
                                hint="...so I can..."
                                label="What You Want (5)"
                                param="aspiration_5"
                                :value="formData['aspiration_5']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            ></input-text>

                            <input-text
                                placeholder=""
                                hint='( e .g. "create a faster... reduce the time it takes to... " )'
                                label="Result (5)"
                                param="result_5"
                                :value="formData['result_5']"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            ></input-text>
                        </input-multiple>

                        <form-subheader
                            title="Secondary Goals"
                            description="Consider other things that your target audience will be
                        able to achieve if the main goals were attained."
                        ></form-subheader>

                        <input-list
                            question="What other objectives, goals, needs, etc. your target audience want to achieve?"
                            placeholder=""
                            hint='( e.g. "Take a week vacation with my family", "Work less hours" )'
                            label="Desires"
                            param="desires"
                            :value="formData['desires']"
                        ></input-list>

                        <form-subheader
                            title="Added Value"
                            description="Consider how your audience lives, jobs, environment, and relationships
                        would improve. In other words, what are the values would be created for your audience
                        if their goals and aspirations were met?"
                        ></form-subheader>

                        <input-list
                            question="(as a customer) I will gain... I will create..."
                            placeholder=""
                            hint='...if aspirations were met, you will... (e.g. "be unable to...", "lose the ability to...")'
                            label="Gains"
                            param="gains"
                            :value="formData['gains']"
                        ></input-list>

                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import FormSubheader from "@/components/input/InputSubheader.vue";
import InputText from "@/components/input/InputText.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputList from "@/components/input/InputList.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormGoals",
    components: {
        FormHeader,
        FormSubheader,
        InputText,
        InputMultiple,
        InputList,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>

<style scoped>
/* .form-company-info {
    font-family: "Roboto", sans-serif;
}

.form-company-info .form-group label {
    color: #666666;
}
.form-company-info .form-group p {
    font-size: 15px;
    color: #999999;
}
@media (max-width: 768px) {
    .form-company-info .form-group label {
        font-size: 15px;
    }
} */
</style>
