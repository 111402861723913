<template>
    <svg
        width="33"
        height="33"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="16"
            cy="16"
            r="15.5"
            fill="#ffffff"
            stroke="#666666"
        />
        <line
            x1="8.27818"
            y1="23.8382"
            x2="23.8345"
            y2="8.28182"
            stroke="#666666"
            stroke-width="2"
        />
        <line
            x1="8.27815"
            y1="8.28183"
            x2="23.8345"
            y2="23.8382"
            stroke="#666666"
            stroke-width="2"
        />
    </svg>
</template>

<script>
    export default {
        name: 'RemoveEntryIcon'
    }
</script>