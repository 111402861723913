<template lang="">
    <div class="form-wrapper shadow-sm rounded-5 cold-md-12 col-xl-10 bg-white mx-auto p-5">

        <!-- <FormContainer :form="$route.params.form" :update="update"></FormContainer> -->
        

        
        <!-- Buiness Section -->
        <FormBusinessHypothesis class="pt-4"
            v-if="$route.params.form == 'hypothesis'"
        ></FormBusinessHypothesis>
        
        <!-- Hypothesis Analysis -->
        <FormHypothesisAnalysis class="pt-4"
            :form="`analysis`"
            v-if="$route.params.form == 'analysis'"
        ></FormHypothesisAnalysis>
        
        <!-- Overview -->
        <FormOverview class="pt-4"
            :form="`overview`"
            v-if="$route.params.form == 'overview'"
        ></FormOverview>


        <!-- Report -->
        <FormReport class="pt-4"
            :form="`report`"
            v-if="$route.params.form == 'report'"
        ></FormReport>



        <!-- Audience Section -->
        <FormAudience class="pt-4"
            :form="`audience`"
            v-if="$route.params.form == 'audience'"
        ></FormAudience>

        <FormProblems class="pt-4"
            :form="`problems`"
            v-if="$route.params.form == 'problems'"
        ></FormProblems>

        <FormGoals class="pt-4"
            :form="`goals`"
            v-if="$route.params.form == 'goals'"
        ></FormGoals>

        <FormTransformation class="pt-4"
            :form="`transformation`"
            v-if="$route.params.form == 'transformation'"
        ></FormTransformation>

        <!-- Product Section -->
        <FormProductDetails class="pt-4"
            :form="`product`"
            v-if="$route.params.form == 'product'"
        ></FormProductDetails>

        <FormBenefits class="pt-4"
            :form="`benefits`"
            v-if="$route.params.form == 'benefits'"
        ></FormBenefits>

        <FormOffer class="pt-4"
            :form="`offer`"
            v-if="$route.params.form == 'offer'"
        ></FormOffer>

        <FormIncentives class="pt-4"
            :form="`incentives`"
            v-if="$route.params.form == 'incentives'"
        ></FormIncentives>

        <FormTestimonials class="pt-4"
            :form="`testimonials`"
            v-if="$route.params.form == 'testimonials'"
        ></FormTestimonials>

        <FormFAQ class="pt-4"
            :form="`faq`"
            v-if="$route.params.form == 'faq'"
        ></FormFAQ>


        <!-- <pre>{{info}}</pre> -->
    </div>
</template>
<script>




// import FormContainer from "@/components/forms/FormContainer.vue";



/* Business */
import FormBusinessHypothesis from "@/components/forms/FormBusinessHypothesis.vue";
import FormHypothesisAnalysis from "@/components/forms/FormHypothesisAnalysis.vue";
import FormOverview from "@/components/forms/FormOverview.vue";
import FormReport from "@/components/forms/FormReport.vue";
// import FormCompanyInfo from "@/components/forms/FormCompanyInfo.vue";

/* Audiences */
import FormAudience from "@/components/forms/FormAudience.vue";
import FormProblems from "@/components/forms/FormProblems.vue";
import FormGoals from "@/components/forms/FormGoals.vue";
import FormTransformation from "@/components/forms/FormTransformation.vue";

/* Product */
import FormProductDetails from "@/components/forms/FormProductDetails.vue";
import FormBenefits from "@/components/forms/FormBenefits.vue";
import FormOffer from "@/components/forms/FormOffer.vue";
import FormIncentives from "@/components/forms/FormIncentives.vue";
import FormTestimonials from "@/components/forms/FormTestimonials.vue";
import FormFAQ from "@/components/forms/FormFAQ.vue";



export default {
    name: 'Forms',

    components: {
        // FormContainer,
        /* Business */
        FormBusinessHypothesis,
        FormHypothesisAnalysis,
        FormOverview,
        FormReport,
        // FormCompanyInfo,
        /* Audiences */
        FormAudience,
        FormProblems,
        FormGoals,
        FormTransformation,
        /* Product */
        FormProductDetails,
        FormBenefits,
        FormOffer,
        FormIncentives,
        FormTestimonials,
        FormFAQ
    }, 

    computed: {

        info() {
            let data, name = this.$route.params.opportunity;
            if(typeof this.$store.state.active.opportunities[name] != 'undefined') {
                data = this.$store.state.active.opportunities[name];
            } else {
                data = {};
            }
            return data
        }
    }
    
}
</script>
<style scoped>

.rounded-5 {
    border-radius: .5rem!important;
}
.form-wrapper {
    max-width: 990px;
    min-width: 670px;
}   
</style>