<template>
    <component :is="choice"></component>
</template>

<script>


import CompanyInfoIcon from './CompanyInfoIcon.vue';
import BusinessHypothesisIcon from './BusinessHypothesisIcon.vue';
import HypothesisAnalysisIcon from './HypothesisAnalysisIcon.vue';
import AudienceIcon from './AudienceIcon.vue';
import ProblemsIcon from './ProblemsIcon.vue';
import GoalsIcon from './GoalsIcon.vue';
import TransformationIcon from './TransformationIcon.vue';
import DetailsIcon from './DetailsIcon.vue';
import BenefitsIcon from './BenefitsIcon.vue';
import OfferIcon from './OfferIcon.vue';
import IncentivesIcon from './IncentivesIcon.vue';
import TestimonialsIcon from './TestimonialsIcon.vue';
import SettingsIcon from './SettingsIcon.vue';
import DollarIcon from './DollarIcon.vue';
import RemoveEntryIcon from './RemoveEntryIcon.vue';
import MailIcon from './MailIcon.vue';


export default {
    props: ['icon'],

    components: {
        CompanyInfoIcon,
        BusinessHypothesisIcon,
        HypothesisAnalysisIcon,
        AudienceIcon,
        ProblemsIcon,
        GoalsIcon,
        TransformationIcon,
        DetailsIcon,
        BenefitsIcon,
        OfferIcon,
        IncentivesIcon,
        TestimonialsIcon,
        SettingsIcon,
        RemoveEntryIcon,
        DollarIcon,
        MailIcon
    }, 

    data (){
        return {
            choice: "CompanyInfoIcon"
        }
    },

    mounted() {
        this.choice = this.icon;
    }
}
</script>
