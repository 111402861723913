<template>
    <section
        id="form-business-hypothesis"
        class="form form-business-hypothesis"
    >
        <div class="container">

            <form-header
                title="Business Hypothesis"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="https://docs.google.com/document/d/1EGKTmLoPPkkUW1SLHURC_Xp8zUi4Gl-MlvWh-m04sOw/edit?usp=sharing"
                description="Formulate a hypothesis in which you identify a group 
                            of individuals who have a common, oppressing problem that is painful enough 
                            for them to pay to make it go away."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">

                        <!-- <input-section
                            title="COMPONENTS"
                            description="Your business hypothesis is made out of 5 elements: 
                                audience, problem, feeling associated with the emotion, a solution, and a transformation."
                        >
                        </input-section> -->


                        <input-textarea
                            question="What would you call your target audience?"
                            placeholder=""
                            hint='(  e.g. "Upscale restaurants in NYC" )'
                            label="Target Audience"
                            param="audience"
                            :value="formData['audience']"
                        >
                        </input-textarea>

                        <input-textarea
                            question="What is the main problem that you are trying to solve?"
                            placeholder=""
                            hint='( ...something they want to achieve but they currently can&apos;t. E.g. "they are unhappy <b>ABOUT</b>..." )'
                            label="Main Challenge"
                            param="problem"
                            :value="formData['problem']"
                        >
                        </input-textarea>



                        <input-select
                            question="How does your audience feel about their main challenge?"
                            placeholder=""
                            hint='( e.g. ...Customers <b>ARE</b> "frustrated"... )'
                            label="Emotion"
                        >
                            <input-emotion
                                param="emotion"
                                :value="formData['emotion']"
                            ></input-emotion>
                        </input-select>



                        <input-textarea
                            question="What value will your audience get when you solve the problem for them?"
                            placeholder=""
                            hint="( ...<b>IF they were able to</b>... )"
                            label="Value Proposition"
                            param="solution"
                            :value="formData['solution']"
                        >
                        </input-textarea>

                        <input-textarea
                            question="What are the positive changes that your audience will experience when they use your solution?"
                            placeholder=""
                            hint="( ...THEN they could... )"
                            label="Positive Transformation"
                            param="transformation"
                            :value="formData['transformation']"
                        >
                        </input-textarea>

                        <!-- <div class="form-header pt-4">
                            <div class="form-group mb-0 row">
                                <div class="offset-md-3 col-md-9">
                                    <h3 class="fw-normal h2 mb-2">
                                        Preview
                                    </h3>
                                </div>
                            </div>
                        </div> -->


                        <form-subheader
                            title="Preview"
                            description=""
                        ></form-subheader>

                        <!-- <input-textarea
                            question="Complete Business Hypothesis"
                            placeholder=""
                            hint="( IMPORTANT: Do not edit )"
                            label="Business Hypothesis"
                            param="preview"
                            :value="data['preview']"
                        >
                        </input-textarea> -->


                        <input-hypothesis
                            question="Your Business Hypothesis"
                            placeholder=""
                            hint="( Hypothesis preview )"
                            label="Business Hypothesis"
                            param="preview"
                            :value="formData['preview']"
                            :form="formData"
                        >
                        </input-hypothesis>

                        <input-textarea
                            question="Summarize your Business Hypothesis"
                            placeholder=""
                            hint="( Create a shorter version —One sentence )"
                            label="Hypothesis Summary"
                            param="summary"
                            :value="formData['summary']"
                        >
                        </input-textarea>


                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import InputDone from "@/components/input/InputDone.vue";
import InputTextarea from "@/components/input/InputTextarea.vue";
import InputHypothesis from "@/components/input/InputHypothesis.vue";
import InputSelect from "@/components/input/InputSelect.vue";
import InputEmotion from "@/components/input/InputEmotion.vue";
import common from "@/components/forms/FormCommon.js";
import FormSubheader from "@/components/input/InputSubheader.vue";

export default {
    name: "FormBusinessHypothesis",
    components: {
        FormHeader,
        FormSubheader,
        InputSelect,
        InputDone,
        InputTextarea,
        InputHypothesis,
        InputEmotion,
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>