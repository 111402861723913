<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light"
    >
        <option></option>
        <option>one time</option>
        <option>per day</option>
        <option>per week</option>
        <option>per month</option>
        <option>per quarter</option>
        <option>per year</option>
        <option>annually</option>
    </select>
</template>

<script>
export default {
    name: "InputFrequency",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
