<template>
    <section
        id="form-benefits"
        class="form form-benefits"
    >

        <div class="container">
            <form-header
                title="Features And Benefits"
                time="5 minutes"
                video="https://www.youtube.com/embed/toh_UHyvOos"
                examples="http://appcropolis.com/mobile-templates/"
                description="When your customer buy from you, what are the key benefits that they will enjoy?"
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">
                        <input-select
                            question="What is the guarantee term for your product/service?"
                            hint="For how long can your customers try your product/service/solution, and expect a full refund if they are not happy with the results?"
                            label="Guarantee"
                            param="guarantee"
                        >
                            <input-guarantee
                                param="guarantee"
                                :value="formData['guarantee']"></input-guarantee>
                        </input-select>

                        <input-multiple
                            v-for="(group, index) in [1, 2, 3, 4, 5]" :key="index"
                            question="As a creator/seller... The product offers/delivers/achives customers' goals... because it has..."
                            param="promise_1"
                        >
                            <input-text
                                hint="...which is good for you (customer) because..."
                                :label="`Feature ` +  group"
                                :param="`feature_` + group"
                                :value="formData['feature_' + group]"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0px!important',
                                    marginBottom: '-20px!important',
                                }"
                            ></input-text>

                            <input-text
                                hint="...the value of this is ($)..."
                                :label="`Benefit ` +  group"
                                :param="`benefit_` +  group"
                                :value="formData['benefit_' + group]"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0px!important',
                                    marginBottom: '-20px!important',
                                }"
                            >
                            </input-text>

                            <input-text
                                hint=""
                                :label="`Price ` +  group"
                                :param="`price_` + group"
                                :value="formData['price_' + group]"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                            </input-text>
                        </input-multiple>

                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import InputText from "@/components/input/InputText.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputSelect from "@/components/input/InputSelect.vue";
import InputGuarantee from "@/components/input/InputGuarantee.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormBenefits",
    components: {
        FormHeader,
        InputText,
        InputMultiple,
        InputSelect,
        InputGuarantee,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>

<style scoped>
/* .form-company-info {
    font-family: "Roboto", sans-serif;
}

.form-company-info .form-group label {
    color: #666666;
}
.form-company-info .form-group p {
    font-size: 15px;
    color: #999999;
}
@media (max-width: 768px) {
    .form-company-info .form-group label {
        font-size: 15px;
    }
} */
</style>
