<template>
    <section
        id="form-transformation"
        class="form form-transformation"
    >
    
        <!-- <input-progress :progress="progress"></input-progress> -->

        <div class="container">
            <form-header
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                title="Transformation"
                description="Vehicles to achieve your audience goals. Put yourself in the shoes of your customers and think about the time when yhou were trying to achive your goals —Feel free to ask your customers direclty."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">
                        <input-multiple
                            question="As a customer, before achieving my goals, what obstacles prevented me from reaching my goals and how I overcame them (vehicles)?"
                            label="Instagram Profile URL"
                            param="1"
                        >
                            <input-text-single
                                label="Obstacle 1"
                                param="obstacle_1"
                                :value="formData['obstacle_1']"
                            ></input-text-single>

                            <input-text-single
                                label="Goal 1"
                                param="goal_1"
                                :value="formData['goal_1']"
                            ></input-text-single>

                            <input-text-single
                                label="Vehicle 1"
                                param="vehicle_1"
                                :value="formData['vehicle_1']"
                            ></input-text-single>
                        </input-multiple>

                        <input-multiple param="2">
                            <input-text-single
                                label="Obstacle 2"
                                param="obstacle_2"
                                :value="formData['obstacle_2']"
                            ></input-text-single>

                            <input-text-single
                                label="Goal 2"
                                param="goal_2"
                                :value="formData['goal_2']"
                            ></input-text-single>

                            <input-text-single
                                label="Vehicle 2"
                                param="vehicle_2"
                                :value="formData['vehicle_2']"
                            ></input-text-single>
                        </input-multiple>

                        <input-multiple param="3">
                            <input-text-single
                                label="Obstacle 3"
                                param="obstacle_3"
                                :value="formData['obstacle_3']"
                            ></input-text-single>

                            <input-text-single
                                label="Goal 3"
                                param="goal_3"
                                :value="formData['goal_3']"
                            ></input-text-single>

                            <input-text-single
                                label="Vehicle 3"
                                param="vehicle_3"
                                :value="formData['vehicle_3']"
                            ></input-text-single>
                        </input-multiple>

                        <input-multiple param="4">
                            <input-text-single
                                label="Obstacle 4"
                                param="obstacle_4"
                                :value="formData['obstacle_4']"
                            ></input-text-single>

                            <input-text-single
                                label="Goal 4"
                                param="goal_4"
                                :value="formData['goal_4']"
                            ></input-text-single>

                            <input-text-single
                                label="Vehicle 4"
                                param="vehicle_4"
                                :value="formData['vehicle_4']"
                            ></input-text-single>
                        </input-multiple>




                        <input-multiple param="5">
                            <input-text-single
                                label="Obstacle 5"
                                param="obstacle_5"
                                :value="formData['obstacle_5']"
                            ></input-text-single>

                            <input-text-single
                                label="Goal 5"
                                param="goal_5"
                                :value="formData['goal_5']"
                            ></input-text-single>

                            <input-text-single
                                label="Vehicle 5"
                                param="vehicle_5"
                                :value="formData['vehicle_5']"
                            ></input-text-single>
                        </input-multiple>


                        <input-list
                            question="As a customer/buyer, because of this product/solution/service, I am now able to..."
                            placeholder=""
                            hint='(e.g. "avoid wasting time...", "increase sales by 25%...", "have a better work/life balance...")'
                            label="Transformation"
                            param="others"
                            :value="formData['others']"
                        ></input-list>


                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import InputTextSingle from "@/components/input/InputTextSingle.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputList from "@/components/input/InputList.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormTransformation",
    components: {
        FormHeader,
        InputTextSingle,
        InputMultiple,
        InputList,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>

<style scoped>
.form-company-info {
    font-family: "Roboto", sans-serif;
}

.form-company-info .form-group label {
    color: #666666;
}
.form-company-info .form-group p {
    font-size: 15px;
    color: #999999;
}
@media (max-width: 768px) {
    .form-company-info .form-group label {
        font-size: 15px;
    }
}
</style>
