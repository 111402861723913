<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light"
    >
        <option></option>
        <option value="All">All</option>
        <option value="Female">Female</option>
        <option value="Male">Male</option>
        <option value="Heterosexual">Heterosexual</option>
        <option value="Gay">Gay</option>
        <option value="Lesbian">Lesbian</option>
        <option value="Bisexual">Bisexual</option>
        <option value="Transgender">Transgender</option>
        <option value="Other">Other</option>
    </select>
</template>

<script>
export default {
    name: "InputGender",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
