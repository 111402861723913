<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 2C21.1 2 21.99 2.9 21.99 4L22 22L18 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20ZM20 4H4V16H18.83L20 17.17V4ZM10 12H18V14H10V12ZM18 9H6V11H18V9ZM6 6H18V8H6V6Z" fill="black" fill-opacity="0.54"/>
    </svg>
</template>

<script>
    export default {
        name: 'TestimonialsIcon'
    }
</script>