<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.19 9.13L12 2.5L14.81 9.13L22 9.74L16.54 14.47L18.18 21.5L12 17.77L5.82 21.5L7.46 14.47L2 9.74L9.19 9.13ZM12.97 9.92L12 7.63L11.03 9.9L10.56 11.01L9.36 11.11L6.89 11.32L8.77 12.95L9.68 13.74L9.41 14.92L8.85 17.33L10.97 16.05L12 15.43L13.03 16.07L15.15 17.35L14.59 14.94L14.32 13.76L15.23 12.97L17.11 11.34L14.64 11.13L13.44 11.03L12.97 9.92Z" fill="black" fill-opacity="0.54"/>
    </svg>

</template>

<script>
    export default {
        name: 'BenefitsIcon'
    }
</script>