<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 6.5H17.82C17.93 6.19 18 5.85 18 5.5C18 3.84 16.66 2.5 15 2.5C13.95 2.5 13.04 3.04 12.5 3.85L12 4.52L11.5 3.84C10.96 3.04 10.05 2.5 9 2.5C7.34 2.5 6 3.84 6 5.5C6 5.85 6.07 6.19 6.18 6.5H4C2.89 6.5 2.01 7.39 2.01 8.5L2 19.5C2 20.61 2.89 21.5 4 21.5H20C21.11 21.5 22 20.61 22 19.5V8.5C22 7.39 21.11 6.5 20 6.5ZM15 4.5C15.55 4.5 16 4.95 16 5.5C16 6.05 15.55 6.5 15 6.5C14.45 6.5 14 6.05 14 5.5C14 4.95 14.45 4.5 15 4.5ZM10 5.5C10 4.95 9.55 4.5 9 4.5C8.45 4.5 8 4.95 8 5.5C8 6.05 8.45 6.5 9 6.5C9.55 6.5 10 6.05 10 5.5ZM4 19.5V15.5H12H20V19.5H4ZM4 8.5V13.5H20V8.5H14.92L17 11.33L15.38 12.5L13 9.26L12 7.9L11 9.26L8.62 12.5L7 11.33L9.08 8.5H4Z" fill="black" fill-opacity="0.54"/>
    </svg>

</template>

<script>
    export default {
        name: 'OfferIcon'
    }
</script>