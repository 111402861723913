<template>
    <section
        id="form-offer"
        class="form form-offer"
    >
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="warning d-inline-block pull-right text-warning bg-light btn-pill py-1 px-2"
                        style="margin-top: -50px"
                        >
                        <span class="material-icons-outlined pull-left">
                        science
                        </span>
                        <span class="ms-2">Experimental</span>
                    </div>

                    <!-- errors -->
                    <div v-if="hypothesis?.length == 0 || swot?.length == 0" class="alert alert-warning">
                        <strong class="d-block mb-2">IMPORTANT</strong>
                        <p v-if="hypothesis?.length == 0" class="mb-0">
                            Complete the <strong>Business Hypothesis</strong> form.
                        </p>

                        <p v-if="swot?.length == 0" class="pb-0 mb-0">
                            Complete the <strong>Hypothesis Analysis</strong> form.
                        </p>
                    </div>


                    <!-- info area -->
                    <div v-if="hypothesis.length" class="bg-primary border border-0 fs-2 mb-5 p-3 rounded-3 rounded-4 text-white" >
                        <p class="fs-5 text-uppercase">Business Hypothesis</p>
                        <h3 class="fw-normal" v-html="hypothesis"></h3>
                    </div>

                    <!-- <div class="position-absolute top-0 end-0">PRINT - DOWNLOAD</div> -->
                    
                    <div v-html="swot"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import FormHeader from "@/components/input/InputHeader.vue";
// import InputText from "@/components/input/InputText.vue";
// import InputTextarea from "@/components/input/InputTextarea.vue";
// import InputCallToAction from "@/components/input/InputCallToAction.vue";
// import InputMultiple from "@/components/input/InputMultiple.vue";
// import InputSelect from "@/components/input/InputSelect.vue";
// import InputScarcity from "@/components/input/InputScarcity.vue";
// import InputTimeZone from "@/components/input/InputTimeZone.vue";
// import InputDone from "@/components/input/InputDone.vue";
// import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormReport",
    components: {
        // FormHeader,
        // InputScarcity,
        // InputText,
        // InputTimeZone,
        // InputMultiple,
        // InputSelect,
        // InputTextarea,
        // InputCallToAction,
        // InputDone
    },
    
    data() {
        // return { ...common.data };
    },

    computed: {

        opportunity() {
            return this.$store.state.current.opportunity;
        }, 

        hypothesis() {
            let output = '';
            if(this.$store.state.current.opportunity?.content?.hypothesis?.fields?.preview) {
                output = this.$store.state.current.opportunity.content.hypothesis.fields.preview;
            }

            return output;
        },

        /**
         * SWOT states (indicator values from the "analysis" form)
         * 
         */
        states() {
            let states = null;
            if(this.$store.state.current.opportunity?.content?.analysis?.fields) {
                states = {...this.$store.state.current.opportunity?.content?.analysis?.fields};
                delete states.done;
                this.$store.dispatch('generateSWOT', states);
            }
            return states;
        },

        swot() {
            if(this.states == null) {
                return 'loading...';
            } else {
                return this.$store.state.reports?.swot;
            }
        }, 
    },
    
    // methods: { ...common.methods },

    // mounted: common.mounted
    async mounted() {
        let name = this.$route.params?.opportunity;
        if(name) {
            await this.$store.dispatch('fetchOpportunity', name);
        }
        this.$store.getters.wp.generateSWOTObject(this.states).then(data => console.log(data));
    },
};
</script>
