<template>
    <section 
        id="form-problems"
        class="form form-problems"
    >
        <div class="container">
            <form-header
                title="Problems"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                description="Struggles And Other Solutions. Put yourself in your audience shoes. When completing this form
			answer the questions in the voice of your target audience."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <div class="appc-widget">
                        <form @keydown.enter.prevent="" @submit.prevent="">
                            <input-text
                                question="As a customer, I am currently struggling with"
                                placeholder=""
                                hint="...and it's keeping you from being able to... Do not use possesive words like: your, my, their, his, hers, its, whose, etc."
                                label="Main Challenge"
                                param="main"
                                :value="formData['main']"
                            >
                            </input-text>


                            <input-list
                                question="(as a customer) I face issues like.." 
                                placeholder=""
                                hint='What other problems do I face? (e.g. "Not being able to...", "Cannot do...")'
                                label="Secondary Problems"
                                param="secondary"
                                :value="formData['secondary']"
                            ></input-list>





                            <input-multiple
                                question="As a customer, in order to... you have"
                                hint='( e.g. "tried doing... users did not buy" )'
                                param="tried"
                            >
                                <input-text
                                    placeholder=""
                                    hint="...with little success, and this has resulted in..."
                                    label="What You Tried (1)"
                                    param="tried_1"
                                    :value="formData['tried_1']"
                                    :style="{
                                        paddingTop: '0px !important',
                                        paddingBottom: '0!important',
                                        marginBottom: '-20px!important',
                                    }"
                                ></input-text>

                                <input-text
                                    placeholder=""
                                    hint='( e.g. "tried doing... users did not buy" )'
                                    label="Result (1)"
                                    param="result_1"
                                    :value="formData['result_1']"
                                    :style="{
                                        paddingTop: '0px !important',
                                    }"
                                ></input-text>
                            </input-multiple>

                            <input-multiple
                                question="As a customer, in order to... you have"
                                hint='( e.g. "tried doing... users did not buy" )'
                                param="tried"
                            >
                                <input-text
                                    placeholder=""
                                    hint="...with little success, and this has resulted in..."
                                    label="What You Tried (2)"
                                    param="tried_2"
                                    :value="formData['tried_2']"
                                    :style="{
                                        paddingTop: '0px !important',
                                        paddingBottom: '0!important',
                                        marginBottom: '-20px!important',
                                    }"
                                ></input-text>

                                <input-text
                                    placeholder=""
                                    hint='( e.g. "tried doing... users did not buy" )'
                                    label="Result (2)"
                                    param="result_2"
                                    :value="formData['result_2']"
                                    :style="{
                                        paddingTop: '0px !important',
                                    }"
                                ></input-text>
                            </input-multiple>

                            <input-multiple
                                question="As a customer, in order to... you have"
                                hint='( e.g. "tried doing... users did not buy" )'
                                param="tried"
                            >
                                <input-text
                                    placeholder=""
                                    hint="...with little success, and this has resulted in..."
                                    label="What You Tried (3)"
                                    param="tried_3"
                                    :value="formData['tried_3']"
                                    :style="{
                                        paddingTop: '0px !important',
                                        paddingBottom: '0!important',
                                        marginBottom: '-20px!important',
                                    }"
                                ></input-text>

                                <input-text
                                    placeholder=""
                                    hint='( e.g. "tried doing... users did not buy" )'
                                    label="Result (3)"
                                    param="result_3"
                                    :value="formData['result_3']"
                                    :style="{
                                        paddingTop: '0px !important',
                                    }"
                                ></input-text>
                            </input-multiple>




                            <!-- Alternative Solutions -->
                            <input-subheader
                                title="Alternative Solutions"
                                description=""
                            ></input-subheader>



                            <input-text
                                question="As a customer, If the product didn't exist, you could"
                                hint="...instead... but it would cost..."
                                label="Alternative Solution 1"
                                param="alternative_solution_1"
                                :value="formData['alternative_solution_1']"
                                placeholder=""
                                :style="{
                                    paddingBottom: '0px !important',
                                    marginBottom: '-20px !important',
                                }"
                            ></input-text>

                            <input-text
                                label="Alt. Solution Price 1"
                                param="alternative_price_1"
                                :value="formData['alternative_price_1']"
                                placeholder=""
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0px !important',
                                }"
                            ></input-text>

                            <input-select
                                placeholder=""
                                hint="( e.g. $5,000 one time )"
                                label="Alt. Solution Frequence 1"
                                col="col-4"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            >
                                <input-frequency
                                    param="alternative_frequency_1"
                                    :value="formData['alternative_frequency_1']"
                                ></input-frequency>
                            </input-select>

                            <input-text
                                question="As a customer, If the product didn't exist, you could"
                                hint="...instead... but it would cost..."
                                label="Alternative Solution 2"
                                param="alternative_solution_2"
                                :value="formData['alternative_solution_2']"
                                placeholder=""
                                :style="{
                                    paddingBottom: '0px !important',
                                    marginBottom: '-20px !important',
                                }"
                            ></input-text>

                            <input-text
                                label="Alt. Solution Price 2"
                                param="alternative_price_2"
                                :value="formData['alternative_price_2']"
                                placeholder=""
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0px !important',
                                }"
                            ></input-text>

                            <input-select
                                placeholder=""
                                hint="( e.g. $5,000 one time )"
                                label="Alt. Solution Frequence 2"
                                param="alternative_frequency_2"
                                :value="formData['alternative_frequency_2']"
                                col="col-4"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            >
                                <input-frequency
                                    param="alternative_frequency_2"
                                    :value="formData['alternative_frequency_2']"
                                ></input-frequency>
                            </input-select>

                            <input-text
                                question="As a customer, If the product didn't exist, you could"
                                hint="...instead... but it would cost..."
                                label="Alternative Solution 3"
                                param="alternative_solution_3"
                                :value="formData['alternative_solution_3']"
                                placeholder=""
                                :style="{
                                    paddingBottom: '0px !important',
                                    marginBottom: '-20px !important',
                                }"
                            ></input-text>

                            <input-text
                                label="Alt. Solution Price 3"
                                param="alternative_price_3"
                                :value="formData['alternative_price_3']"
                                placeholder=""
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0px !important',
                                }"
                            ></input-text>

                            <input-select
                                placeholder=""
                                hint="( e.g. $5,000 one time )"
                                label="Alt. Solution Frequence 3"
                                param="alternative_frequency_3"
                                :value="formData['alternative_frequency_3']"
                                col="col-4"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            >
                                <input-frequency
                                    param="alternative_frequency_3"
                                    :value="formData['alternative_frequency_3']"
                                ></input-frequency>
                            </input-select>



                            <!-- <div class="form-header pt-4">
                                <div class="form-group mb-0 row">
                                    <div class="offset-md-3 col-md-9">
                                        <h3 class="fw-light h2 mb-2">
                                            What are the future losses or negative consequences if the problems or challenges go unsolved?
                                        </h3>
                                        <p>If the problems that were listed above were not solved, what type of negative consequences or losses will your customer face? —what is at risk</p>
                                    </div>
                                </div>
                            </div> -->

                            <form-subheader
                                title="What other problems and future losses and/or negative consequences will be faced if the problems or challenges go unsolved?"
                                description="If the problems that were listed above were not solved, what type of negative consequences or losses will your customer face? —what is at risk"
                            ></form-subheader>

                            

                            
                            
                            <input-list
                                question="(as a customer) I won't be able to..." 
                                placeholder=""
                                hint='...if problems are not solve, you will... (e.g. "be unable to...", "lose the ability to..")'
                                label="Loses"
                                param="loses"
                                :value="formData['loses']"
                            ></input-list>


                            <input-done
                                question="Mark as complete"
                                placeholder=""
                                hint=""
                                label=""
                                param="done"
                                :value="formData['done']"
                            >
                            </input-done>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import FormSubheader from "@/components/input/InputSubheader.vue";
import InputText from "@/components/input/InputText.vue";
import InputFrequency from "@/components/input/InputFrequency.vue";
import InputSelect from "@/components/input/InputSelect.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputList from "@/components/input/InputList.vue";
import InputDone from "@/components/input/InputDone.vue";
import InputSubheader from "@/components/input/InputSubheader.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormProblems",
    components: {
        FormHeader,
        FormSubheader,
        InputFrequency,
        InputSelect,
        InputText,
        InputMultiple,
        InputList,
        InputSubheader,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>
