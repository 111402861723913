<template>
  <!-- /**
      * @see https://developer.snapappointments.com/bootstrap-select/
    * @see https://www.opensecrets.org/industries/alphalist.php
    */ -->
  <select
    class="form-control form-control-lg bg-light selectpicker-custom"
    data-live-search="true"
    v-model="val"
  >
      <option>Other</option>
    <optgroup label="A">
      <option>Accountants</option>
      <option>Advertising/Public Relations</option>
      <option>Aerospace, Defense Contractors</option>
      <option>Agribusiness</option>
      <option>Agricultural Services & Products</option>
      <option>Agriculture</option>
      <option>Air Transport</option>
      <option>Air Transport Unions</option>
      <option>Airlines</option>
      <option>Alcoholic Beverages</option>
      <option>Alternative Energy Production & Services</option>
      <option>Architectural Services</option>
      <option>Attorneys/Law Firms</option>
      <option>Auto Dealers</option>
      <option>Auto Dealers, Japanese</option>
      <option>Auto Manufacturers</option>
      <option>Automotive</option>
    </optgroup>

    <optgroup label="B">
      <option>Banking, Mortgage</option>
      <option>Banks, Commercial</option>
      <option>Banks, Savings & Loans</option>
      <option>Bars & Restaurants</option>
      <option>Beer, Wine & Liquor</option>
      <option>Books, Magazines & Newspapers</option>
      <option>Broadcasters, Radio/TV</option>
      <option>Builders/General Contractors</option>
      <option>Builders/Residential</option>
      <option>Building Materials & Equipment</option>
      <option>Building Trade Unions</option>
      <option>Business Associations</option>
      <option>Business Services</option>
    </optgroup>

    <optgroup label="C">
      <option>Cable & Satellite TV Production & Distribution</option>
      <option>Candidate Committees</option>
      <option>Candidate Committees, Democratic</option>
      <option>Candidate Committees, Republican</option>
      <option>Car Dealers</option>
      <option>Car Dealers, Imports</option>
      <option>Car Manufacturers</option>
      <option>Casinos / Gambling</option>
      <option>Cattle Ranchers/Livestock</option>
      <option>Chemical & Related Manufacturing</option>
      <option>Chiropractors</option>
      <option>Civil Servants/Public Officials</option>
      <option>Clergy & Religious Organizations</option>
      <option>Clothing Manufacturing</option>
      <option>Coal Mining</option>
      <option>Colleges, Universities & Schools</option>
      <option>Commercial Banks</option>
      <option>Commercial TV & Radio Stations</option>
      <option>Communications/Electronics</option>
      <option>Computer Software</option>
      <option>Conservative/Republican</option>
      <option>Construction</option>
      <option>Construction Services</option>
      <option>Construction Unions</option>
      <option>Credit Unions</option>
      <option>Crop Production & Basic Processing</option>
      <option>Cruise Lines</option>
      <option>Cruise Ships & Lines</option>
    </optgroup>

    <optgroup label="D">
      <option>Dairy</option>
      <option>Defense</option>
      <option>Defense Aerospace</option>
      <option>Defense Electronics</option>
      <option>Defense/Foreign Policy Advocates</option>
      <option>Democratic Candidate Committees</option>
      <option>Democratic Leadership PACs</option>
      <option>Democratic/Liberal</option>
      <option>Dentists</option>
      <option>Doctors & Other Health Professionals</option>
      <option>Drug Manufacturers</option>
    </optgroup>

    <optgroup label="E">
      <option>Education</option>
      <option>Electric Utilities</option>
      <option>Electronics Manufacturing & Equipment</option>
      <option>Electronics, Defense Contractors</option>
      <option>Energy & Natural Resources</option>
      <option>Entertainment Industry</option>
      <option>Environment</option>
    </optgroup>

    <optgroup label="F">
      <option>Farm Bureaus</option>
      <option>Farming</option>
      <option>Finance / Credit Companies</option>
      <option>Finance, Insurance & Real Estate</option>
      <option>Food & Beverage</option>
      <option>Food Processing & Sales</option>
      <option>Food Products Manufacturing</option>
      <option>Food Stores</option>
      <option>For-profit Education</option>
      <option>For-profit Prisons</option>
      <option>Foreign & Defense Policy</option>
      <option>Forestry & Forest Products</option>
      <option>Foundations, Philanthropists & Non-Profits</option>
      <option>Funeral Services</option>
    </optgroup>

    <optgroup label="G">
      <option>Gambling & Casinos</option>
      <option>Gambling, Indian Casinos</option>
      <option>Garbage Collection/Waste Management</option>
      <option>Gas & Oil</option>
      <option>Gay & Lesbian Rights & Issues</option>
      <option>General Contractors</option>
      <option>Government Employee Unions</option>
      <option>Government Employees</option>
      <option>Gun Control</option>
      <option>Gun Rights</option>
    </optgroup>

    <optgroup label="H">
      <option>Health</option>
      <option>Health Professionals</option>
      <option>Health Services/HMOs</option>
      <option>Hedge Funds</option>
      <option>HMOs & Health Care Services</option>
      <option>Home Builders</option>
      <option>Hospitals & Nursing Homes</option>
      <option>Hotels, Motels & Tourism</option>
      <option>Human Rights</option>
    </optgroup>

    <optgroup label="I">
      <option>Ideological/Single-Issue</option>
      <option>Indian Gaming</option>
      <option>Industrial Unions</option>
      <option>Insurance</option>
      <option>Internet</option>
      <option>Israel Policy</option>
    </optgroup>

    <optgroup label="L">
      <option>Labor</option>
      <option>Lawyers & Lobbyists</option>
      <option>Lawyers / Law Firms</option>
      <option>Leadership PACs</option>
      <option>Liberal/Democratic</option>
      <option>Liquor, Wine & Beer</option>
      <option>Livestock</option>
      <option>Lobbyists</option>
      <option>Lodging / Tourism</option>
      <option>Logging, Timber & Paper Mills</option>
    </optgroup>

    <optgroup label="M">
      <option>Manufacturing, Misc</option>
      <option>Marine Transport</option>
      <option>Meat processing & products</option>
      <option>Medical Supplies</option>
      <option>Mining</option>
      <option>Misc Business</option>
      <option>Misc Finance</option>
      <option>Misc Manufacturing & Distributing</option>
      <option>Misc Unions</option>
      <option>Miscellaneous Defense</option>
      <option>Miscellaneous Services</option>
      <option>Mortgage Bankers & Brokers</option>
      <option>Motion Picture Production & Distribution</option>
      <option>Music Production</option>
    </optgroup>

    <optgroup label="N">
      <option>Natural Gas Pipelines</option>
      <option>Newspaper, Magazine & Book Publishing</option>
      <option>Non-profits, Foundations & Philanthropists</option>
      <option>Nurses</option>
      <option>Nursing Homes/Hospitals</option>
      <option>Nutritional & Dietary Supplements</option>
    </optgroup>

    <optgroup label="O">
      <option>Oil & Gas</option>
      <option>Other</option>
    </optgroup>

    <optgroup label="P">
      <option>Payday Lenders</option>
      <option>Pharmaceutical Manufacturing</option>
      <option>Pharmaceuticals / Health Products</option>
      <option>Phone Companies</option>
      <option>Physicians & Other Health Professionals</option>
      <option>Postal Unions</option>
      <option>Poultry & Eggs</option>
      <option>Power Utilities</option>
      <option>Printing & Publishing</option>
      <option>Private Equity & Investment Firms</option>
      <option>Pro-Israel</option>
      <option
        >Professional Sports, Sports Arenas & Related Equipment &
        Services</option
      >
      <option>Progressive/Democratic</option>
      <option>Public Employees</option>
      <option>Public Sector Unions</option>
      <option>Publishing & Printing</option>
    </optgroup>

    <optgroup label="R">
      <option>Radio/TV Stations</option>
      <option>Railroads</option>
      <option>Real Estate</option>
      <option>Record Companies/Singers</option>
      <option>Recorded Music & Music Production</option>
      <option>Recreation / Live Entertainment</option>
      <option>Religious Organizations/Clergy</option>
      <option>Republican Candidate Committees</option>
      <option>Republican Leadership PACs</option>
      <option>Republican/Conservative</option>
      <option>Residential Construction</option>
      <option>Restaurants & Drinking Establishments</option>
      <option>Retail Sales</option>
      <option>Retired</option>
    </optgroup>

    <optgroup label="S">
      <option>Savings & Loans</option>
      <option>Schools/Education</option>
      <option>Sea Transport</option>
      <option>Securities & Investment</option>
      <option>Special Trade Contractors</option>
      <option>Sports, Professional</option>
      <option>Steel Production</option>
      <option>Stock Brokers/Investment Industry</option>
      <option>Student Loan Companies</option>
      <option>Sugar Cane & Sugar Beets</option>
    </optgroup>

    <optgroup label="T">
      <option>Teachers Unions</option>
      <option>Teachers/Education</option>
      <option>Telecom Services & Equipment</option>
      <option>Telephone Utilities</option>
      <option>Textiles</option>
      <option>Timber, Logging & Paper Mills</option>
      <option>Tobacco</option>
      <option>Transportation</option>
      <option>Transportation Unions</option>
      <option>Trash Collection/Waste Management</option>
      <option>Trucking</option>
      <option>TV / Movies / Music</option>
      <option>TV Production</option>
    </optgroup>

    <optgroup label="U">
      <option>Unions</option>
      <option>Unions, Airline</option>
      <option>Unions, Building Trades</option>
      <option>Unions, Industrial</option>
      <option>Unions, Misc</option>
      <option>Unions, Public Sector</option>
      <option>Unions, Teacher</option>
      <option>Unions, Transportation</option>
      <option>Universities, Colleges & Schools</option>
    </optgroup>

    <optgroup label="V">
      <option>Vegetables & Fruits</option>
      <option>Venture Capital</option>
    </optgroup>

    <optgroup label="W">
      <option>Waste Management</option>
      <option>Wine, Beer & Liquor</option>
      <option>Women's Issues</option>
    </optgroup>
  </select>
</template>

<script>
export default {
  name: "InputIndustry",
  props: ["param", "value"],

  data: function() {
    return {};
  },
  computed: {
    val: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$parent.update(this.param, newValue);
      },
    },
  },


  watch: {
    value() {
      this.val = this.value;
    }
  },

  
  mounted: function() {
    // window.$(this.$el).selectpicker({
    //   style: "text-dark border bg-light btn-input-industry",
    // });
  }

};
</script>

<style>
.btn-input-industry {
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  /* color: green !important; */
}
</style>
