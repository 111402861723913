<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light col-3"
    >
        <option></option>
        <option>second</option>
        <option>seconds</option>
        <option>minute</option>
        <option>minutes</option>
        <option>hour</option>
        <option>hours</option>
        <option>day</option>
        <option>days</option>
        <option>week</option>
        <option>weeks</option>
        <option>month</option>
        <option>months</option>
        <option>year</option>
        <option>years</option>
    </select>
</template>

<script>
export default {
    name: "InputTimeFrameUnit",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
