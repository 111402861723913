<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light col-3"
    >
        <option></option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>10</option>
        <option>20</option>
        <option>50</option>
        <option>75</option>
        <option>100</option>
        <option>200</option>
        <option>500</option>
        <option>1000</option>
        <option>1000+</option>
    </select>
</template>

<script>
export default {
    name: "InputEmployees",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
