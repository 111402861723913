<template>
    <div :class="param" class="py-4">
        <div class="form-group mb-0 row">
            <div class="offset-md-3 col-md-9">
                <h4 class="fw-normal">{{ question }}</h4>
            </div>
            <label
                for="colFormLabel"
                class="col-md-3 col-form-label col-form-label-lg text-md-right pl-md-0"
                >{{ label }}</label
            >
            <div class="col-md-9">
                <input
                    type="text"
                    class="form-control form-control-lg bg-light"
                    :name="param"
                    :id="param"
                    :placeholder="placeholder"
                    v-model="val"
                />
            </div>
            <div class="offset-md-3 col-md-9">
                <p class="pl-md-2 text-muted">{{ hint }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputText",
    props: ["question", "placeholder", "label", "hint", "param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
