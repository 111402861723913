<template>
    <section
        id="form-offer"
        class="form form-offer"
    >
        <div class="container">
            <form-header
                title="Product Offer"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                description="If you could offer a promotion, how would you describe the terms of that offer?"
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">
                        <input-text
                            question="Who is sending communications to the customer? —use your name or an alias"
                            hint='(e.g. "John")'
                            label="Sender's Name"
                            param="sender"
                            :value="formData['sender']"
                        >
                        </input-text>

                        <input-text
                            question="The person sending communications is a/an..."
                            hint='This is why cusrtomers should trust you are (sender is) 
                        qualified to solve the problems. (e.g."Full stack Web Developer 
                        with 10 years of experience")'
                            label="Sender's Role/Experience"
                            param="sender_role"
                            :value="formData['sender_role']"
                        >
                        </input-text>

                        <input-select
                            question="Is this offer available on specific dates or just for limited amount?"
                            hint="( e.g. Date/Time )"
                            label="Scarcity"
                            param="scarcity"
                        >
                            <input-scarcity
                                param="scarcity"
                                :value="formData['scarcity']"></input-scarcity>
                        </input-select>

                        <input-text
                            question='Available amount —if Scarcity is based on "Availability"'
                            hint='(e.g. 15 "Days")'
                            label="Amount"
                            param="amount"
                            :value="formData['amount']"
                        >
                        </input-text>

                        <input-multiple
                            question="The product promotion starts on..."
                            param="start"
                        >
                            <input-text
                                label="Date"
                                hint="( e.g. 10/15/2021)"
                                param="start_date"
                                :value="formData['start_date']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            >
                            </input-text>

                            <input-text
                                label="Time"
                                hint="( e.g. 8:00 AM)"
                                param="start_time"
                                :value="formData['start_time']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            >
                            </input-text>

                            <input-select
                                hint="( e.g. Date/Time )"
                                label="Time Zone"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            >
                                <input-time-zone
                                param="start_time_zone"
                                :value="formData['start_time_zone']"></input-time-zone>
                            </input-select>
                        </input-multiple>

                        <input-multiple
                            question="The product promotion ends on..."
                            param="end"
                        >
                            <input-text
                                label="Date"
                                hint="( e.g. 10/15/2021)"
                                param="end_date"
                                :value="formData['end_date']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            >
                            </input-text>

                            <input-text
                                label="Time"
                                hint="( e.g. 8:00 AM)"
                                param="end_time"
                                :value="formData['end_time']"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                    marginBottom: '-20px!important',
                                }"
                            >
                            </input-text>

                            <input-select
                                hint="( e.g. Date/Time )"
                                label="Time Zone"
                                param="end_time_zone"
                                :style="{
                                    paddingTop: '0px !important',
                                }"
                            >
                                <input-time-zone
                                param="end_time_zone"
                                :value="formData['end_time_zone']"></input-time-zone>
                            </input-select>
                        </input-multiple>

                        <input-textarea
                            question="Why is this a limited time offer? ...this is a limited offer because..."
                            hint="State the reason why you are making a limited time offer."
                            label="Limited Offer Reason"
                            param="reason"
                            :value="formData['reason']"
                        >
                        </input-textarea>

                        <input-select
                            question="What do you expect customers to do after reading your 
                        communication (emails, sales letter, ads, etc.)?"
                            hint="Choose a call to action from the list."
                            label="Call To Action"
                            param="call_to_action"
                        >
                            <input-call-to-action
                                param="call_to_action"
                                :value="formData['call_to_action']"></input-call-to-action>
                        </input-select>


                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import InputText from "@/components/input/InputText.vue";
import InputTextarea from "@/components/input/InputTextarea.vue";
import InputCallToAction from "@/components/input/InputCallToAction.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputSelect from "@/components/input/InputSelect.vue";
import InputScarcity from "@/components/input/InputScarcity.vue";
import InputTimeZone from "@/components/input/InputTimeZone.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormOffer",
    components: {
        FormHeader,
        InputScarcity,
        InputText,
        InputTimeZone,
        InputMultiple,
        InputSelect,
        InputTextarea,
        InputCallToAction,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>
