<template>
    <select
    :id="param"
    :name="param"
    v-model="val"
	class="form-control form-control-lg bg-light col-3">
        <option></option>
        <option value="B2C">B2C</option>
        <option value="B2B">B2B</option>
    </select>
</template>

<script>
export default {
    name: "InputType",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
