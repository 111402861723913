<template>
    <section
        id="form-product-details"
        class="form form-product-details"
    >
    
        <div class="container">
            <form-header
                title="Product Details"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                description="Describe your product or solution."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">
                        <input-text
                            question="What is the name of the product/service/solution?"
                            hint='...think in terms of solutions (e.g."AI powered marketing automation")'
                            label="Product Name"
                            param="name"
                            :value="formData['name']"
                        >
                        </input-text>

                        <input-textarea
                            question="How would you describe your product?"
                            hint="In a few sentence, describe what your solution is (200 chr Max)."
                            label="Product Description"
                            param="description"
                            :value="formData['description']"
                        >
                        </input-textarea>

                        <input-text
                            question="(as a company/seller)... I want to help people..."
                            hint='State what is your goal in 1-3 words (ideally). (e.g. I wna to help people "start a business")'
                            label="Product Goal"
                            param="goal"
                            :value="formData['goal']"
                        >
                        </input-text>

                        <input-select
                            question="Your product/service/solution is a"
                            hint='(Choose the type of product you offer, e.g. "book", "course", etc.)'
                            label="Product Type"
                        >
                            <input-product-type
                                param="type"
                                :value="formData['type']"
                            ></input-product-type>
                        </input-select>

                        <!-- <input-text
                            question="In general terms, who is your target audience? —grouping name or label (TODO: link to @audience/Name Your audience)"
                            hint='(Customer segment name. e.g. "Small Businesses looking to sell products online")'
                            label="Your Target Audience"
                            param="audience"
                            :value="formData['audience']"
                        >
                        </input-text> -->

                        <input-text
                            question="(as a company/seller)... I was able to..."
                            hint='What is the biggest transformation that you deliver to your 
                        audience (avoid using posessive pronouns like "your" 
                        "my" "their" "his" "hers" 
                        "its", "whose" etc.). E.g. "created the 
                        first online marketplace for..."'
                            label="Core Deliverable"
                            param="deliverable"
                            :value="formData['deliverable']"
                        >
                        </input-text>

                        <input-multiple
                            question='How long will it take for someone to achieve the "Promised Result" specified above?'
                            param="time_frame"
                        >
                            <input-select
                                placeholder=""
                                label="Time Frame (Amount)"
                                param="timeframe_amount"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-time-frame-amount
                                    param="timeframe_amount"
                                    :value="formData['timeframe_amount']"
                                ></input-time-frame-amount>
                            </input-select>
                            <input-select
                                placeholder=""
                                hint="( e.g. 7 days )"
                                label="Time Frame (Unit)"
                                param="timeframe_unit"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-time-frame-unit
                                    param="timeframe_unit"
                                    :value="formData['timeframe_unit']"
                                ></input-time-frame-unit>
                            </input-select>
                        </input-multiple>

                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import InputText from "@/components/input/InputText.vue";
import InputTextarea from "@/components/input/InputTextarea.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputProductType from "@/components/input/InputProductType.vue";
import InputSelect from "@/components/input/InputSelect.vue";
import InputTimeFrameAmount from "@/components/input/InputTimeFrameAmount.vue";
import InputTimeFrameUnit from "@/components/input/InputTimeFrameUnit.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormProductDetails",
    components: {
        FormHeader,
        InputTextarea,
        InputText,
        InputMultiple,
        InputSelect,
        InputProductType,
        InputTimeFrameAmount,
        InputTimeFrameUnit,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>

<style scoped>
/* .form-company-info {
    font-family: "Roboto", sans-serif;
}

.form-company-info .form-group label {
    color: #666666;
}
.form-company-info .form-group p {
    font-size: 15px;
    color: #999999;
}
@media (max-width: 768px) {
    .form-company-info .form-group label {
        font-size: 15px;
    }
} */
</style>
