<template>
    <div :class="param" class="py-4">
        <div class="form-group mb-0 row">
            <div class="offset-md-3 col-md-9">
                <h4 class="fw-normal">{{ question }}</h4>
            </div>

            <label
                for="colFormLabel"
                class="col-md-3 col-form-label col-form-label-lg text-md-right pl-md-0"
                >{{ label }}</label
            >
            <div :class="[col ? col : 'col-md-9']" class="">
                <slot></slot>
            </div>
            <div class="offset-md-3 col-md-9" v-show="!!hint">
                <p class="pl-md-2 text-muted" v-html="hint"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputSelect",
    props: ["question", "placeholder", "label", "hint", "param", "col"],

    data() {
        return {};
    },

    methods: {
        update(key, val) {
            this.$parent.progress = true;
            this.$parent.update(key, val);
        },
    },
};
</script>
