<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light"
    >
        <option></option>
        <option>High school diploma or equivalent</option>
        <option>No college</option>
        <option>Some college, no degree</option>
        <option>College</option>
        <option>Postsecondary non-degree award</option>
        <option>Graduate School</option>
        <option>Associate’s degree</option>
        <option>Bachelor’s degree</option>
        <option>Master’s degree</option>
        <option>Doctoral or professional degree</option>
        <option>Various levels of education</option>
    </select>
</template>

<script>
export default {
    name: "InputEducation",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
