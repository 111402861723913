<template>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C8.14 2 5 5.14 5 9C5 11.38 6.19 13.47 8 14.74V17C8 17.55 8.45 18 9 18H15C15.55 18 16 17.55 16 17V14.74C17.81 13.47 19 11.38 19 9C19 5.14 15.86 2 12 2ZM9 21C9 21.55 9.45 22 10 22H14C14.55 22 15 21.55 15 21V20H9V21ZM14 13.7L14.85 13.1C16.2 12.16 17 10.63 17 9C17 6.24 14.76 4 12 4C9.24 4 7 6.24 7 9C7 10.63 7.8 12.16 9.15 13.1L10 13.7V16H14V13.7Z" fill="black" fill-opacity="0.54"/>
        </svg>
</template>

<script>
    export default {
        name: 'BusinessHypothesis'
    }
</script>

<style lang="scss" scoped>

</style>