<template>


    <div :class="param" class="pt-5 pb-4">
        <div class="form-group mb-0 row">
            <div class="offset-md-3 col-md-9">
                <button
                    @click="done"
                    :id="param"
                    :class="[val === true? 'complete' : '']"
                    class="btn btn-done btn-lg px-3 position-relative"
                >
                    <span class="icon-checked material-icons position-absolute mr-3" v-if="val">
                        check_box
                    </span>
                    <span class="icon-checked material-icons position-absolute mr-3" v-if="!val">
                        check_box_outline_blank
                    </span>
                    <span class="btn-label mr-2">
                        {{ question }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormInputAge",
    props: ["param", "value", "question"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                let val = typeof this.value == 'undefined'? false : this.value;
                    val = this.value == 'true' || this.value === true;
                return val;
            },
            set: function(newValue) {
                let flag = newValue == 'true' || newValue === true;
                this.$parent.update(this.param, flag);
            },
        },
    },

    methods: {
        done() {
            this.val = !this.val;
        }
    }
};
</script>


<style lang="css" scoped>

.btn-done {   
    background-color: var(--bg-secondary);
}

.btn-done .btn-label {   
    margin-left: 36px;
}

.btn-done.complete {
    color: #fff !important;    
    background-color: var(--success);
}

.btn-done .material-icons {
    font-size: 28px;   
}


.btn-done .icon-checked {
    margin-top: 2px;
}

</style>