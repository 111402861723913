<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light col-6"
    >
        <option></option>
        <option>book</option>
        <option>bootcamp</option>
        <option>class</option>
        <option>course</option>
        <option>digital goods</option>
        <option>ebook</option>
        <option>marketplace</option>
        <option>mastermind</option>
        <option>posdcast</option>
        <option>product</option>
        <option>professional services</option>
        <option>program</option>
        <option>service</option>
        <option>solution</option>
        <option>software</option>
        <option>subscription</option>
    </select>
</template>

<script>
export default {
    name: "InputProductType",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
