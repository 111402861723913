<template lang="">
    <div id="sidebar">
        <ul id="opportunity-sidebar-nav" class="nav nav-tabs">
            <li class="nav-item " v-for="(tab, index) in tabs" :key="index">
                <a class="nav-link text-reset fw-500 font-size-18" :data-href="`#${tab.url}`">{{tab.title}}</a>
            </li>
        </ul>

        <div class="tab-content p-2 pt-3">
            <div v-for="(tab, index) in tabs" :key="index" :id="tab.url"
                class="tab-pane"  
                :class="[current.tabURL == tab.url? 'active': '']"
                >
                <ul class="list-group list-group-flush">
                    <li v-for="(form, j) in tab.forms" :key="j"
                        class="list-group-item position-relative rounded-3 border-0 mt-0"
                        :class="[current.formName == form.name? 'active': '', 
                            opportunity && opportunity?.content[form.name]?.fields?.done === true ||
                            opportunity && opportunity?.content[form.name]?.fields?.done === 'true'? 'completed': '']"
                        >
                        <a href="#" class="stretched-link text-reset text-decoration-none fw-500 font-size-18" @click.prevent="setForm(form)">
                            <IconButton label="" :icon="form.icon" class=""></IconButton>
                            <span class="ms-2">
                                {{form.title}}      
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

import IconButton from "@/components/IconButton.vue";
// const $ = window.$;
const bootstrap = window.bootstrap;


export default {
    components: {
        IconButton
    },
    
    data() {
        return {
            current: {
                tab: null,
                tabURL: '',
                form: null,
                formName: ''
            },
        }
    },

    computed:{
        tabs() {
            return this.$store.getters.tabs;
        }, 

        opportunity() {
            return this.$store.getters.currentOpportunity;
        }

    },
    
    watch: {
        $route(to, from) {
            to;  from;
            if(this.$route.params.formGroup) {
                this.tabs.forEach((tab)=>{
                    if(tab.url == this.$route.params.formGroup) {
                        this.setTab(tab);
                    }
                });
            }

            if(this.$route.params.form) {
                this.current.tab.forms.forEach((form)=>{
                    if(form.name == this.$route.params.form) {
                        this.setForm(form);
                    }
                });
            }
        }
    },


    methods: {

        initTabs() {
            this.tabs.forEach((item)=>{
                let tab = document.querySelector('#opportunity-sidebar-nav [data-href="#'+ item.url +'"]');
                    tab.addEventListener('click', (event)=> {
                        event.preventDefault();
                        this.setTab(item);
                    });
                item.trigger = new bootstrap.Tab(tab);
            });
        }, 


        setTab(tab) {
            tab.trigger.show();
            this.current.tab = tab;
            this.current.tabURL = tab.url;
        }, 


        setForm(form) {
            this.current.form = form;
            this.current.formName = form.name;
            let url = this.getBaseURL() + '/' + this.current.tab.url +'/'+ this.current.formName;
            this.$store.dispatch('setCurrentForm', form);
            this.$router.push({ path: url });
        },


        getBaseURL() {
            let url = '/edit/opportunities/' + this.$route.params.opportunity;
            return url;
        }

    }, 

    created() {
        setTimeout(() => {
            let firstTab, firstForm;
            if(this.$route.params.formGroup) {
                this.tabs.forEach((tab)=>{
                    if(tab.url == this.$route.params.formGroup) {
                        firstTab = tab;
                    }
                });
            } else {
                firstTab = this.tabs[0];
            }

            this.initTabs();
            this.setTab(firstTab);

            if(this.$route.params.form) {
                firstTab.forms.forEach((form)=>{
                    if(form.name == this.$route.params.form) {
                        firstForm = form;
                    }
                });
            } else {
                firstForm = firstTab.forms[0];
            }

            this.setForm(firstForm);
        }, 100);
    }

}
</script>
<style scoped>

.nav-item {
    width: 33%;
}

.list-group-item {
    cursor: pointer;
    margin-bottom: 2px;
    transition: all 0.2s;
}
.list-group-item.active {
    background-color: var(--primary-light) !important;
}
.list-group-item:not(.active):hover {
    background-color: var(--primary-lighter) !important;
}
.list-group-item.active a {
    color: var(--text-main) !important;
}
.list-group-item.active .btn-icon {
    background-color: var(--primary) !important;
    color: #ffffff !important;
}

/* completed */
.list-group-item.active.completed {
    background-color: hsl(134 61% 41%/.2)!important;
    color: var(--success)!important;
}
.list-group-item.completed:hover {
    background-color: hsl(134 61% 41%/.3)!important;
}
.list-group-item.completed > a {
    color: var(--bs-success)!important;
}
.list-group-item.completed .btn-icon {
    background-color: var(--success)!important;
    color: var(--bs-white)!important;
}

</style>