<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light col-3"
    >
        <option></option>
        <option>18</option>
        <option>19</option>
        <option>20</option>
        <option>21</option>
        <option>22</option>
        <option>23</option>
        <option>24</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
        <option>50</option>
        <option>55</option>
        <option>60</option>
        <option>65</option>
        <option>70</option>
        <option>75</option>
        <option>80</option>
        <option>85</option>
        <option>90</option>
        <option>95</option>
        <option>100</option>
    </select>
</template>

<script>
export default {
    name: "FormInputAge",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
