<template lang="">
    <div data-cmp="NavBar" class="appc-widget d-flex flex-row">
        <div class="align-items-center d-flex">
            <a @click.prevent="$router.back()" class="btn btn-primary btn-sm font-size-16 --me-2 px-1 rounded-pill flex-shrink-0">
                <span class="material-icons pull-left">
                chevron_left
                </span>
            </a>
        </div>

        <div class="flex-fill flex-shrink-0">
            <nav class="ps-3" style="--bs-breadcrumb-divider: '›';" aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                    <router-link class="breadcrumb-item text-decoration-none" :to="$root.links.DASHBOARD">
                        <span class="pe-2">{{$root.labels.DASHBOARD}}</span>
                    </router-link>

                    <router-link class="breadcrumb-item text-decoration-none" :to="$root.links.MY_OPPORTUNITIES + ($route.params?.opportunity? '/' + $route.params.opportunity : '')">
                        <span class="pe-2">{{$root.labels.OFFERS}}</span>
                    </router-link>
                    
                    <li class="breadcrumb-item" v-if="!planURL">
                        ...
                    </li>

                    <li class="breadcrumb-item" v-if="planURL">
                        {{opportunity.title}} 
                    </li>
                    <li class="breadcrumb-item">
                        {{currentForm.title}}
                    </li>
                </ol>
            </nav>
        </div>

        <div class="align-items-center d-flex flex-shrink-0">
            <!-- <router-link class="btn btn-outline-primary btn-sm font-size-16 me-2 pe-3 rounded-pill" :to="`/reports/opportunities/${$route.params.opportunity}/swot`">
                <span class="material-icons-outlined pull-left me-1">
                pie_chart
                </span>
                <span class="">Reports</span>
            </router-link> -->


            <router-link class="btn btn-sm btn-primary rounded-pill font-size-16 ps-2 pe-3 me-2" :to="$root.links.LIBRARY_STRATEGIES_AI">
                <span class="material-icons pull-left me-1">
                add
                </span>
                <span class="pe-1">Add Strategy</span>
            </router-link>

            <ButtonStrategiesDropdown></ButtonStrategiesDropdown>


            <!-- <a @click.prevent="$router.back()" class="btn btn-circle p-0">
                <span class="material-icons mx-auto w-100">
                more_horiz
                </span>
            </a> -->
        </div>
    </div>

</template>
<script>

import ButtonStrategiesDropdown from "@/components/ButtonStrategiesDropdown.vue";

export default {

    components: {
        ButtonStrategiesDropdown
    },

    computed:{
        opportunity() {
            let opportunity = this.$store.state.current.opportunity;
            if(opportunity == null) {
                opportunity = {}
            }
            return opportunity;
        }, 

        currentForm() {
            return this.$store.state.current.form || {};
        },

        planURL() {
            let url = null;
            if(this.$store.state.current.opportunity != null) {
                url = `/explore/opportunities/${this.$route.params.opportunity}/plan`;
            }
            return url;
        }
    }
    
}
</script>
<style lang="css" scoped>

</style>