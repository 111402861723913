<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light col-3"
    >
        <option></option>
        <option>$0</option>
        <option>$15k</option>
        <option>$20k</option>
        <option>$25k</option>
        <option>$30k</option>
        <option>$45k</option>
        <option>$60k</option>
        <option>$75k</option>
        <option>$90k</option>
        <option>$100k</option>
        <option>$125k</option>
        <option>$150k</option>
        <option>$175k</option>
        <option>$200k</option>
        <option>$250k</option>
        <option>$300k</option>
        <option>$350k</option>
        <option>$400k</option>
        <option>$500k+</option>
    </select>
</template>

<script>
export default {
    name: "InputIncome",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
