<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100" data-cmp="OpportunityDetails">
        <AppHeader></AppHeader>
        <section class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12 d-flex flex-row">
                    <div class="sidebar position-fixed">
                        <Sidebar></Sidebar>
                    </div>
                    <div class="content bg-canvas d-flex flex-column flex-fill">
                        <div class="navbar-wrapper py-navbar bg-white border-bottom border-start ps-3 pe-4 py-2.5 --shadow-sm">
                            <NavBar></NavBar>
                        </div>

                        <div class="overflow-scroll pb-5 pt-5 px-4 px-lg-4 shadow-inset-md">
                            <Forms></Forms>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import AppHeader from "@/components/AppHeader";
import Sidebar from "@/views/opportunity/Sidebar.vue";
import NavBar from "@/views/opportunity/NavBar.vue";
import Forms from "@/views/opportunity/Forms.vue";


export default {
    name: "OpportunityMain",

    components: {
        AppHeader,
        Sidebar,
        NavBar,
        Forms
    },
    
    computed: {

    },

    mounted() {
        let name = this.$route.params.opportunity;
        this.$store.dispatch('fetchOpportunity', name);
    },
}
</script>
<style scoped>
.sidebar {
   width: 320px; 
}
.content {
   margin-left: 320px; 
   height: calc(100vh - 86px);
}

.navbar-wrapper {
    z-index: 10;
}

.shadow-inset-md {
    /* box-shadow: inset 4px -4px 8px -4px rgb(0 0 0 / 10%); */
    box-shadow: inset 0 .125rem .25rem rgba(0,0,0,.075);
}


.py-navbar {
    padding-top: 11px!important;
    padding-bottom: 11px!important;
}
</style>