


// import debounce from '@/plugins/utils.js';

export default {
    data: {
        // PUSH_DELAY: 2000,
        progress: false, 
        form: null
    },

    computed: {
        formData() {
            let data = {}; 
            let form = this.$route.params.form;
            let opportunity = this.$store.getters.currentOpportunity; 

            if(opportunity) {
                data = this.$store.getters.currentOpportunity?.content?.[form]?.fields;
            }

            return data || {};
        },

        PUSH_DELAY() {
            return this.$store.state.global.PUSH_DELAY;
        }
    },

    methods: {
        debounce(func, delay) {
            let inDebounce;
            return function() {
                const context = this
                const args = arguments
                clearTimeout(inDebounce)
                inDebounce = setTimeout(() => func.apply(context, args), delay)
            }
        },
        /**
         * Refidined on "restore"
         * 
         * @param {*} field 
         * @param {*} value 
         */
        update(field, value) { 
            // console.log(field, value);
            this.formData[field] = value;
            this.push(field, value);
        },


        /**
         * Commit changes
         * 
         * @param {*} prop 
         * @param {*} value 
         */
        save(field, value) {
            value;
            // do not save if there is no opportunity
            if(!this.$route.params?.opportunity) {
                return;
            }

            // do not save if there is no opportunity
            if(this.form != this.$route.params.form) {
                // this.$root.notify({
                //     type: 'alert', 
                //     message: 'Unable to save your last change'
                // });
                return;
            }

            // console.log('SAVE', field, value);
            // console.log('DATA', this.formData);

            let request = {
                field: field,
                value: this.formData,
                form: this.form,
                opportunity_id: this.$store.state.active.opportunities[ this.$route.params.opportunity ].ID
            };

            // this.$store.dispatch('updateOpportunityFormField', request);
            this.$store.dispatch('updateOpportunityForm', request);
        }

    },

    mounted() {
        var scope = this;
        this.form = this.$route.params.form;
        this.push =  this.debounce(function() {
            scope.save.apply(scope, arguments);
        }, this.PUSH_DELAY);
    },
}