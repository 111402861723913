<template>
  <section 
        id="form-testimonials"
        class="form form-testimonials">

	<div class="container">

            <form-header
                title="Testimonials"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                description="Provide short quotes or personal anecdotes from clients that describe 
                the positive experience that they had when using your solution."
            ></form-header>


		<div class="row">
			<div class="col-lg-12">
                <form @keydown.enter.prevent="" @submit.prevent="">

                    <input-multiple v-for="index in [1, 2, 3, 4, 5]" :key="index"
                        question="Provide the name of the person who gave the testimonial, 
                        the testimonial, position, and a public URL (e.g. company's website, 
                        LinkedIn profile, etc.)"
                        :param="`${index}`"
                        >
                        <input-text
                            :label="`First Name ${index}`"
                            :param="`firstname_${index}`"
                            :value="formData['firstname_' + index]"
                            hint="( e.g. John )"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        <input-text
                            :label="`Last Name ${index}`"
                            :param="`lastname_${index}`"
                            :value="formData['lastname_' + index]"
                            hint="( e.g. Doe )"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>

                        <input-textarea
                            :label="`Testimonial ${index}`"
                            :param="`comment_${index}`"
                            :value="formData['comment_' + index]"
                            hint="( Transcribe what your customers are saying about you/your company )"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-textarea>
                        
                        <input-text
                            :label="`Company ${index}`"
                            :param="`company_${index}`"
                            :value="formData['company_' + index]"
                            hint="( e.g. ACME Inc. )"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            :label="`Position or Role ${index}`"
                            :param="`role_${index}`"
                            :value="formData['role_' + index]"
                            hint="( e.g. VP Of Sales. )"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            :label="`Profile Image URL ${index}`"
                            :param="`image_url_${index}`"
                            :value="formData['image_url_' + index]"
                            hint="( e.g. &quot;https://example.com/profile.png&quot; )"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            :label="`Company Logo URL ${index}`"
                            :param="`logo_url_${index}`"
                            :value="formData['logo_url_' + index]"
                            hint="( e.g. &quot;https://example.com/logo.png&quot; )"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            :label="`Website URL ${index}`"
                            :param="`website_url_${index}`"
                            :value="formData['website_url_' + index]"
                            hint="( e.g. &quot;https://acmeinc.com&quot; )"
                            :style="{
                                paddingTop: '0px !important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            :label="`More ${index}`"
                            :param="`more_url_${index}`"
                            :value="formData['more_url_' + index]"
                            hint="( e.g. &quot;https://somethingelse.com&quot; )"
                            :style="{
                                paddingTop: '0px !important'
                            }"
                        >
                        </input-text>
                    </input-multiple>


                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>

				</form>
			</div>
		</div>
	</div>
</section>
</template>




<script>

import FormHeader from "@/components/input/InputHeader.vue";
import InputText from "@/components/input/InputText.vue";
import InputTextarea from "@/components/input/InputTextarea.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";


export default {
  name: "FormTestimonials",
  components: {
    FormHeader,
    InputText,
    InputTextarea,
    InputMultiple,
    InputDone
  },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};


</script>
