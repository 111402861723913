<template>
    <div class="form-header pt-4">
        <div class="form-group mb-0 row">
            <div class="col-md-9 offset-md-3">
                <h3 class="fw-light mb-2">{{title}}</h3>
                <p class="text-muted">{{description}}</p>
            </div>
        </div>
    </div>
</template>


<script>
export default {
  name: "InputSubheader",
  props: {
      title: String,
      description: String
  }
};
</script>