<template>
    <div class="row pb-4">
        <div class="col-12 col-md-3 text-right pt-4">
                <svg :style="{ width: '100%'}" width="200" height="200" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="127" height="127" rx="15.5" fill="white" stroke="#EBEBEB"/>
                <rect x="11.5" y="11.5" width="105" height="105" rx="7.5" stroke="#E4E6EB" stroke-dasharray="3 3"/>
                <line x1="5.35355" y1="5.64645" x2="122.354" y2="122.646" stroke="#E4E6EB" stroke-dasharray="3 3"/>
                <line x1="4.64645" y1="122.646" x2="122.646" y2="4.64645" stroke="#E4E6EB" stroke-dasharray="3 3"/>
            </svg>
        </div>
        
        <div class="col-12 col-md-9 text-md-left">
            <div class="appc-widget banner-text pt-4">
                <h2 class="mb-3 text-dark fw-light">{{ title }}</h2>
                <p class="text-muted" :class="[time? 'mb-0': '']">{{ description }}</p>
                <small v-if="time" class="d-block pb-4 pt-1 text-muted text-time">( Estimated completion time: <b class="text-orange">{{ time }}</b> )</small>

                <div>
                    <a v-if="video" class="btn btn-flat border-radius-8 pe-3 me-3 disabled" href="#"
                        @click.prevent="watchVideo()"
                        >
                        <span class="material-icons-outlined pull-left me-1">
                        play_circle
                        </span>
                        <span class="ml-2">Watch the video</span>
                    </a> 
                    

                    <a v-if="examples" class="btn btn-flat border-radius-8 pe-3" href="#"
                        @click.prevent="action('moreInfo')">
                        <span class="material-icons-outlined pull-left me-1">
                        description
                        </span>
                        <span class="ml-2">Learn more</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
  name: "InputHeader",
  props: {
      title: String,
      time: String,
      description: String, 
      video: String,
      examples: String
  }, 

  methods: {
      action(name) {
          if(name == 'moreInfo') {
            this.$root.action('openWindow', {
                url: this.examples
            });
          }
      },
      watchVideo() {
        this.$root.watchVideo(this.video);
      }
  }
};
</script>


<style scoped>
.text-time {
    /* font-style: italic; */
    letter-spacing: 0.3px;
}
/* .btn-more {
    border-color: var(--primary-medium);
    color: var(--primary);
}

.btn-more:hover {
    background-color: var(--primary);
    color: #fff !important;
    border-color: transparent;
}

.btn-more svg path {
    fill: var(--primary);
}

.btn-more:hover svg path {
    fill: #ffffff;
} */
</style>