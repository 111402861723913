<template>
    <section
        id="form-audience"
        class="form form-audience"
    >
        <div class="container">
            <form-header
                title="Details"
                time="7 minutes"
                video="https://www.youtube.com/embed/SfkWI9L-854"
                examples="http://appcropolis.com/mobile-templates/"
                description="Capture your target audience's demographic information and 
                identify other important characteristics that will help you determine who
                your buyer is."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">
                        <input-text
                            question="What would you like to name this audience profile?"
                            placeholder=""
                            hint='To choose a name, fill in the blank: "We are trying to help...(?)"'
                            label="Name Your audience"
                            param="name"
                            :value="formData['name']"
                        ></input-text>

                        <input-select
                            question="Is your target audience businesses or individuals?"
                            placeholder=""
                            hint="( B2B or B2C )"
                            label="Type Of Audience"
                            param="type"
                        >
                            <input-type
                                param="type"
                                :value="formData['type']"
                            ></input-type>
                        </input-select>

                        <input-select
                            question="What Is Your Target Prospect Gender?"
                            placeholder=""
                            hint='( Choose the gender of your customer segment. "All" is set by default )'
                            label="Audience Gender"
                            param="gender"
                        >
                            <input-gender
                                param="gender"
                                :value="formData['gender']"
                            ></input-gender>
                        </input-select>

                        <input-select
                            question="What is your audience education level?"
                            placeholder=""
                            hint="( Indicate education level required to use your product. e.g. $60K - $100K )"
                            label="Education Level"
                            param="education"
                        >
                            <input-education
                                param="education"
                                :value="formData['education']"
                            ></input-education>
                        </input-select>

                        <input-select
                            question="What is the primary role or position of most of your target customers?"
                            placeholder=""
                            hint="Your audience occupation is mainly..."
                            label="Role Or Position"
                            param="role"
                        >
                            <input-role
                                param="role"
                                :value="formData['role']"
                            ></input-role>
                        </input-select>

                        <!-- <input-text
                            question="What is the minimum number of years of experience?"
                            placeholder=""
                            hint='Minimum number of years of experience (e.g. 4)'
                            label="Years Of Experience"
                            param="experience"
                            :value="formData['experience']"
                        ></input-text> -->






                        <input-select
                            question="What is the minimum number of years of experience?"
                            placeholder=""
                            hint="Minimum number of years of experience (e.g. 4)"
                            label="Years Of Experience"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                            }"
                        >
                            <input-experience
                                param="experience"
                                :value="formData['experience']"
                            ></input-experience>
                        </input-select>



                        <input-multiple
                            question="What is your Target Prospect age range?"
                            hint="( There is no other solution that can address the challenge )"
                            param="age_range"
                        >
                            <input-select
                                placeholder=""
                                hint=""
                                label="Age Range Min"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-age
                                    param="age_min"
                                    :value="formData['age_min']"
                                ></input-age>
                            </input-select>
                            <input-select
                                placeholder=""
                                hint='( e.g. ...Customers ARE "frustrated"... )'
                                label="Age Range Max"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-age
                                    param="age_max"
                                    :value="formData['age_max']"
                                ></input-age>
                            </input-select>
                        </input-multiple>

                        <input-multiple
                            question="What is your audience income level?"
                            hint="( There is no other solution that can address the challenge )"
                            param="income_range"
                        >
                            <input-select
                                placeholder=""
                                hint=""
                                label="Income Min"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-income
                                    param="income_min"
                                    :value="formData['income_min']"
                                ></input-income>
                            </input-select>

                            <input-select
                                placeholder=""
                                hint="( e.g. $60K - $100K )"
                                label="Income Max"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-income
                                    param="income_max"
                                    :value="formData['income_max']"
                                ></input-income>
                            </input-select>
                        </input-multiple>

                        <form-subheader
                            title="B2C Audience Only"
                            description=""
                        ></form-subheader>

                        <input-select
                            question="Does your target audience have Children?"
                            placeholder=""
                            hint="( Indicate if people within your audience segment have children )"
                            label="Have Children"
                            param="children"
                        >
                            <input-confirm
                                param="children"
                                :value="formData['children']"
                            ></input-confirm>
                        </input-select>

                        <input-select
                            question="What is your audience marrige status?"
                            placeholder=""
                            hint="( Marriage status of most people within your audience )"
                            label="Marriage Status"
                            param="marriage_status"
                        >
                            <input-marriage
                                param="marriage_status"
                                :value="formData['marriage_status']"
                            ></input-marriage>
                        </input-select>

                        <!-- B2B Audience Only -->
                        <form-subheader
                            title="B2B Audience Only"
                            description=""
                        ></form-subheader>

                        <input-select
                            question="In which type of industry is your customer?"
                            placeholder=""
                            hint='( e.g. "Hospitality" )'
                            label="Industry"
                            param="industry"
                        >
                            <input-industry
                                param="industry"
                                :value="formData['industry']"
                            ></input-industry>
                        </input-select>

                        <input-text
                            question="In which segment of the selected inductry is your customer?"
                            placeholder=""
                            hint='( e.g. "Resturants" )'
                            label="Industry Segment"
                            param="industry_segment"
                            :value="formData['industry_segment']"
                        ></input-text>

                        <input-multiple
                            question="How many employees (range) your target companies have?"
                            hint=""
                            param="employee_range"
                        >
                            <input-select
                                placeholder=""
                                hint=""
                                label="Employees Min"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-employees
                                    param="employee_range_min"
                                    :value="formData['employee_range_min']"
                                ></input-employees>
                            </input-select>
                            <input-select
                                placeholder=""
                                hint="( e.g. 10-20 )"
                                label="Employees Max"
                                :style="{
                                    paddingTop: '0px !important',
                                    paddingBottom: '0!important',
                                }"
                            >
                                <input-employees
                                    param="employee_range_max"
                                    :value="formData['employee_range_max']"
                                ></input-employees>
                            </input-select>
                        </input-multiple>

                        <!-- Qualifiers -->
                        <form-subheader
                            title="Qualifiers —Why is this the right audience?"
                            description="What are the characteristics, habits, behaviors, believes, 
                            challenges, preferences, desires, etc. that make you believe that 
                            the chosen audience is interested in your product/service:"
                        ></form-subheader>

                        <div class="form-header">
                            <div class="form-group mb-0 row">
                                <div class="offset-md-3 col-md-9">
                                    <ul class="pl-1 ml-3 text-muted">
                                        <li>Needs your product/services</li>
                                        <li>Has the money to pay for it</li>
                                        <li>Wish to take action (buy) relatively soon</li>
                                        <li>Trust you, your solution, and his/her ability to adopt it</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <input-list
                            question="This is my target audience because he/she/it... (e.g. has/wants/needs/looking for, etc.)"
                            placeholder=""
                            hint='( e.g. "Trying to do... withing the next ... days" )'
                            label="Qualifiers"
                            param="qualifiers"
                            :value="formData['qualifiers']"
                        ></input-list>



                        <!-- Disqualifiers -->
                        <form-subheader
                            title="Disqualifiers —Signs that suggest someone is not your target audience"
                            description="Can you think of characteristics or reasons that, when present, may be an indicator that someone is not your target audience —red flags."
                        ></form-subheader>

                        <input-list
                            question="Can you think of 'red flags' that may suggest someone is not part of my target audience?"
                            placeholder=""
                            hint='( e.g. "Trying to do... withing the next ... days" )'
                            label="Disqualifiers"
                            param="disqualifiers"
                            :value="formData['disqualifiers']"
                        ></input-list>

                        <!-- mark as complete -->
                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import FormHeader from "@/components/input/InputHeader.vue";
import FormSubheader from "@/components/input/InputSubheader.vue";
import InputAge from "@/components/input/InputAge.vue";
import InputGender from "@/components/input/InputGender.vue";
import InputIncome from "@/components/input/InputIncome.vue";
import InputConfirm from "@/components/input/InputConfirm.vue";
import InputEducation from "@/components/input/InputEducation.vue";
import InputType from "@/components/input/InputType.vue";
import InputText from "@/components/input/InputText.vue";
import InputSelect from "@/components/input/InputSelect.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputRole from "@/components/input/InputRole.vue";
import InputMarriage from "@/components/input/InputMarriage.vue";
import InputIndustry from "@/components/input/InputIndustry.vue";
import InputEmployees from "@/components/input/InputEmployees.vue";
import InputList from "@/components/input/InputList.vue";
import InputExperience from "@/components/input/InputExperience.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormAudience",
    components: {
        FormHeader,
        FormSubheader,
        InputAge,
        InputGender,
        InputIncome,
        InputConfirm,
        InputEducation,
        InputText,
        InputType,
        InputSelect,
        InputMultiple,
        InputRole,
        InputMarriage,
        InputIndustry,
        InputEmployees,
        InputList,
        InputExperience,
        InputDone,
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>