<template>
    <section
        id="form-hypothesis-analysis"
        class="form form-hypothesis-analysis"
    >
        <div class="container">
            <form-header
                title="Hypothesis Analysis"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="https://docs.google.com/document/d/1vd-_3uodnbbi7EPknhOAQLUsbm6hvQK4-_KeGoXRofk/edit?usp=sharing"
                description="Complete this section to estimate the viability of your business hypothesis. 
                Learn about your hypothesis strength, weaknesses, opportunities, and potential challenges ahead of you."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <form @keydown.enter.prevent="" @submit.prevent="">
                        <input-select
                            v-for="(indicator, index) in indicators" :key="index"
                            :question="indicator.post_content"
                            :hint="indicator.post_excerpt"
                            :label="indicator.post_title"
                            :param="indicator.post_name"
                            placeholder=""
                        >
                            <input-rate
                                :param="indicator.post_name"
                                :value="formData[indicator.post_name]"
                            ></input-rate>
                        </input-select>


                        <input-done
                            question="Mark as complete"
                            placeholder=""
                            hint=""
                            label=""
                            param="done"
                            :value="formData['done']"
                        >
                        </input-done>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InputSelect from "@/components/input/InputSelect.vue";
import InputDone from "@/components/input/InputDone.vue";
import InputRate from "@/components/input/InputRate.vue";
import FormHeader from "@/components/input/InputHeader.vue";
import common from "@/components/forms/FormCommon.js";

export default {
    name: "FormBusinessHypothesis",
    components: {
        InputSelect,
        InputDone,
        FormHeader,
        InputRate,
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed, ...{
            indicators() {
                let ind = this.$store.state?.reports?.indicators;
                if(!ind) {
                    this.$store.dispatch('getIndicators');
                }
                return ind;
            }
        }
    },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>
