<template>
  <section 
        id="form-incentives"
        class="form form-incentives"
    >
	<div class="container">

            <form-header
                title="Incentives"
                time="5 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                description="Everybody likes to feel that they are getting a good deal. What type of incentive do you offer?"
            ></form-header>


		<div class="row">
			<div class="col-lg-12">
                <form @keydown.enter.prevent="" @submit.prevent="">
                    <input-multiple
                        v-for="(group, index) in [1, 2, 3, 4, 5, 6]" :key="index"
                        question="As a creator/seller, talking to the customer... 
                        the name of the first bonus included is"
                        param="one"
                        >
                        <input-text
                            :label="`Bonus ${group}`"
                            hint="...and it strengthens the main offer because it will help you..."
                            :param="`bonus_${group}`"
                            :value="formData[`bonus_${group}`]"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            :label="`Benefit ${group}`"
                            hint="...the value of this is..."
                            :param="`benefit_${group}`"
                            :value="formData[`benefit_${group}`]"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>

                        <input-text
                            :label="`Price ${group}`"
                            hint=""
                            :param="`price_${group}`"
                            :value="formData[`price_${group}`]"
                            :style="{
                                paddingTop: '0px !important'
                            }"
                        >
                        </input-text>
                    </input-multiple>


<!-- 
                    <input-multiple
                        question="As a creator/seller, talking to the customer... 
                        the name of the first bonus included is"
                        param="incentive_one"
                        >
                        <input-text
                            label="Bonus 1"
                            hint="...and it strengthens the main offer because it will help you..."
                            param="incentive_bonus_1"
                            :value="formData['incentive_bonus_1']"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            label="Benefit 1"
                            hint="...the value of this is..."
                            param="incentive_benefit_1"
                            :value="formData['incentive_benefit_1']"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>

                        <input-text
                            label="Price 1"
                            hint=""
                            param="incentive_price_1"
                            :value="formData['incentive_price_1']"
                            :style="{
                                paddingTop: '0px !important'
                            }"
                        >
                        </input-text>
                    </input-multiple>



                    <input-multiple
                        param="incentive_two"
                        >
                        <input-text
                            label="Bonus 2"
                            hint="...and it strengthens the main offer because it will help you..."
                            param="incentive_bonus_2"
                            :value="formData['incentive_bonus_2']"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            label="Benefit 2"
                            hint="...the value of this is..."
                            param="incentive_benefit_2"
                            :value="formData['incentive_benefit_2']"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>

                        <input-text
                            label="Price 2"
                            hint=""
                            param="incentive_price_2"
                            :value="formData['incentive_price_2']"
                            :style="{
                                paddingTop: '0px !important'
                            }"
                        >
                        </input-text>
                    </input-multiple>




                    <input-multiple
                        param="incentive_three"
                        >
                        <input-text
                            label="Bonus 3"
                            hint="...and it strengthens the main offer because it will help you..."
                            param="incentive_bonus_3"
                            :value="formData['incentive_bonus_3']"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>
                        
                        <input-text
                            label="Benefit 3"
                            hint="...the value of this is..."
                            param="incentive_benefit_3"
                            :value="formData['incentive_benefit_3']"
                            :style="{
                                paddingTop: '0px !important',
                                paddingBottom: '0!important',
                                marginBottom: '-20px!important'
                            }"
                        >
                        </input-text>

                        <input-text
                            label="Price 3"
                            hint=""
                            param="incentive_price_3"
                            :value="formData['incentive_price_3']"
                            :style="{
                                paddingTop: '0px !important'
                            }"
                        >
                        </input-text>
                    </input-multiple>

 -->

                    <input-done
                        question="Mark as complete"
                        placeholder=""
                        hint=""
                        label=""
                        param="done"
                        :value="formData['done']"
                    >
                    </input-done>

				</form>
			</div>
		</div>
	</div>
</section>
</template>




<script>

import FormHeader from "@/components/input/InputHeader.vue";
import InputText from "@/components/input/InputText.vue";
import InputMultiple from "@/components/input/InputMultiple.vue";
import InputDone from "@/components/input/InputDone.vue";
import common from "@/components/forms/FormCommon.js";


export default {
  name: "FormIncentives",
  components: {
    FormHeader,
    InputText,
    InputMultiple,
    InputDone
  },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>


<style scoped>
/* .form-company-info {
    font-family: 'Roboto', sans-serif;
}

.form-company-info .form-group label{
    color: #666666;
}
.form-company-info .form-group p{
    font-size:15px;
    color: #999999;
}
@media (max-width: 768px) { 
    .form-company-info .form-group label{
        font-size: 15px;
    }
 } */
</style>