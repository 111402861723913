<template>
    <section 
        id="form-problems"
        class="form form-problems"
    >
        <div class="container">
            <form-header
                title="Frequently Asked Questions (FAQ)"
                time="15 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="http://appcropolis.com/mobile-templates/"
                description="Be proactive. Address your audience questions and objections in advance."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <div class="appc-widget">
                        <form @keydown.enter.prevent="" @submit.prevent="">

                            <form-subheader
                                title="Company"
                                description="Address questions related to your company and how you deliver your solution to your customers."
                            ></form-subheader>
                            
                            <input-textarea
                                question="Who are you?"
                                hint="Describe your business or solution in one or two sentences."
                                label="About You"
                                param="who_are_you"
                                :value="formData['who_are_you']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="How long have you been in business?"
                                hint="What can you tell to prove that you know what you are doing."
                                label="Your Experience"
                                param="experience"
                                :value="formData['experience']"
                            >
                            </input-textarea>

                            <input-textarea
                                question="How big is your company?"
                                hint="Can you take care of your prospect's needs?"
                                label="Capability"
                                param="capability"
                                :value="formData['capability']"
                            >
                            </input-textarea>






                            <form-subheader
                                title="Product"
                                description="Address questions about your product or solution."
                            ></form-subheader>

                            <input-textarea
                                question="What is [SOLUTION]?"
                                hint="in a nutshell, what do you do or offer?"
                                label="Your Solution"
                                param="description"
                                :value="formData['description']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What do I get from [SOLUTION]?"
                                hint="In concrete terms, what do you provide to your customers?"
                                label="Product Description"
                                param="deliverables"
                                :value="formData['deliverables']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="How long does it take for me to see results?"
                                hint="How soon or effective is your solution?"
                                label="Time To See Results"
                                param="time_to_results"
                                :value="formData['time_to_results']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What kind of help or support do I get?"
                                hint="—If I get stuck, would you help me?"
                                label="Support"
                                param="support"
                                :value="formData['support']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Can you guarantee that this will work for me?"
                                hint="—What happen if your solution does not work for me?"
                                label="Guarantee"
                                param="guarantee"
                                :value="formData['guarantee']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What if I don't see improvements in my business after 3 months of using [SOLUTION]?"
                                hint="—I tried, and still I don't see the results I was expecting"
                                label="No Results"
                                param="no_results"
                                :value="formData['no_results']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What makes [SOLUTION] special?"
                                hint="Why should your solution be chosen over your competitors?"
                                label="Unique Differentiators"
                                param="differentiators"
                                :value="formData['differentiators']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What exactly am I signing up for?"
                                hint="—Once I sign-up/purchace, what do I get?"
                                label="Immediate Value"
                                param="immediate_value"
                                :value="formData['immediate_value']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What happens after I sign up?"
                                hint="—I am in... then what?"
                                label="After Signing Up"
                                param="after_signing_up"
                                :value="formData['after_signing_up']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What results do I actually get from [SOLUTION]?"
                                hint="How your solution will positively transform your customers lives"
                                label="Expected Results"
                                param="expected_results"
                                :value="formData['expected_results']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Does it integrate with...?"
                                hint="Relevant technology solutions and technology partners"
                                label="Integrations"
                                param="integrations"
                                :value="formData['integrations']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="How can I get started?"
                                hint="—I am in... how can I get started?"
                                label="Getting Started"
                                param="getting_started"
                                :value="formData['getting_started']"
                            >
                            </input-textarea>







                            <form-subheader
                                title="Pricing"
                                description="Let your prospects know about the value you provide to them and how much will they pay."
                            ></form-subheader>

                            <input-textarea
                                question="How much does your [SOLUTION] cost?"
                                hint="Name your price and value"
                                label="Pricing"
                                param="pricing"
                                :value="formData['pricing']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What happens after I buy?"
                                hint="Set expectations"
                                label="Expectations"
                                param="expectations"
                                :value="formData['expectations']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="If I pay for the [SOLUTION], do I get locked in a contract?"
                                hint="—Is your pricing transparent?"
                                label="Price Transaprency"
                                param="price_transparency"
                                :value="formData['price_transparency']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What are the benefits if I subscribe yearly?"
                                hint="—What are long term benefits of using your solution?"
                                label="Long Term Benefits"
                                param="long_term_benefits"
                                :value="formData['long_term_benefits']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Are there any hidden additional fees?"
                                hint="—Are there other cost you are not telling me about?"
                                label="Additional Costs"
                                param="additional_costs"
                                :value="formData['additional_costs']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="If I am not happy with [SOLUTION], can I get my money back?"
                                hint="Minimize risk for your customers"
                                label="Risk"
                                param="risk"
                                :value="formData['risk']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Can I cancel my account or subscription?"
                                hint="—If I am not happy, can I get out?"
                                label="Cancellation"
                                param="cancellation"
                                :value="formData['cancellation']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Do you send invoices?"
                                hint="—What is your billing process?"
                                label="Billing"
                                param="billing"
                                :value="formData['billing']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Can I change or switch my plan later?"
                                hint="Upgrades and downgrades"
                                label="Upgrades and Downgrades"
                                param="switching_plans"
                                :value="formData['switching_plans']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Can I get a refund?"
                                hint="—If I am not happy, can I get my money back?"
                                label="Refunds"
                                param="refunds"
                                :value="formData['refunds']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Do you offer discounts for non-profit, students, teachers, military personnel, large orders, etc.?"
                                hint="Discounts for special customers"
                                label="Discounts"
                                param="discounts"
                                :value="formData['discounts']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Is my payment information secure?"
                                hint="—How are you going to protect my payment information?"
                                label="Payment Handling"
                                param="payment_handling"
                                :value="formData['payment_handling']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What happens after my trial is over?"
                                hint="If you offer a trial or a promotion, what happens once it is over?"
                                label="End Of Promotion"
                                param="end_of_promotion"
                                :value="formData['end_of_promotion']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What happens once I reach my account  limit?"
                                hint="—It ths solution has constraints, what happens if I need more...?"
                                label="Limitations"
                                param="limitations"
                                :value="formData['limitations']"
                            >
                            </input-textarea>










                            <form-subheader
                                title="Support"
                                description="Buyers must feel confident about buying from you and that 
                                they will have the necessary support to learn how to use your solutions."
                            ></form-subheader>


                            <input-textarea
                                question="What type of technical support do I get?"
                                hint="—I need help with your solution"
                                label="Support"
                                param="support"
                                :value="formData['support']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Do you offer any training?"
                                hint="What efforts do you have in place to help customers use your solution?"
                                label="Training"
                                param="training"
                                :value="formData['training']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="Can I contact you if I get stuck with [SOLUTION]?"
                                hint="How can your customers get help from you fi, or when they need further assistance?"
                                label="Contact"
                                param="contact"
                                :value="formData['contact']"
                            >
                            </input-textarea>




                            <!-- Done -->

                            <input-done
                                question="Mark as complete"
                                placeholder=""
                                hint=""
                                label=""
                                param="done"
                                :value="formData['done']"
                            >
                            </input-done>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import FormSubheader from "@/components/input/InputSubheader.vue";
// import InputText from "@/components/input/InputText.vue";
// import InputFrequency from "@/components/input/InputFrequency.vue";
// import InputSelect from "@/components/input/InputSelect.vue";
// import InputMultiple from "@/components/input/InputMultiple.vue";
// import InputList from "@/components/input/InputList.vue";
// import InputSubheader from "@/components/input/InputSubheader.vue";
import common from "@/components/forms/FormCommon.js";
import InputTextarea from "@/components/input/InputTextarea.vue";
import InputDone from "@/components/input/InputDone.vue";

export default {
    name: "FormFAQ",
    components: {
        FormHeader,
        FormSubheader,
        // InputFrequency,
        // InputSelect,
        // InputText,
        // InputMultiple,
        // InputList,
        // InputSubheader,
        InputTextarea,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>
