<template>
    <div class="cmp-input-rate">
		<div class="d-inline-block mx-3 py-2" v-for="(option, index) in [1, 2, 3, 4, 5]" :key="index">
			<label 
                :for="`option-${option}`"
                @click.prevent="select(option)"
                class="d-block text-center text-secondary">
                <span>{{ option }}</span>
                <input type="radio" :id="`option-${option}`" :name="param" class="" :checked="val === option? true : false">
                <div class="radio position-relative"></div>
			</label>
		</div>
	</div>
</template>

<script>
export default {
    name: "InputRate",
    props: ["param", "value"],
    data: function() {
        return {
        };
    },

    computed: {
        val: {
            get: function() {
                /**
                 * IMPORTANT: values are return as string, so a "+" needs to be added in front to convert to number
                 * (e.g. +"2" => 2)
                 */
                return +this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },

    methods: {
        select(option) {
            if(option === this.val) {
                this.$el.querySelector('#option-' + option).checked = false;
                option = 0;
            } else {
                this.$el.querySelector('#option-' + option).checked = true;
            }
            
            this.val = option;
        }
    }
};
</script>

<style lang="css" scoped>

label:hover {
    cursor: pointer;
}
label span {
    font-size: 18px;
    line-height: 28px;
}


input {
    width: 0;
    height: 0;
}
input + div {
    width: 20px;
    height: 20px;
    border: solid var(--secondary) 2px;
    border-radius: 50%;
}


/* input:checked div ~ span {
    color: var(--primary) !important;
} */


input:checked + div {
    border: solid var(--primary) 2px !important;
}


input:checked + div:before,
input:checked + div:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--primary);
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    margin: 3px -5px;
}


input:checked + div:before {
    width: 26px;
    height: 26px;
    margin: -5px -13px;
    opacity: .2;
    border: solid 10px orange;
}
</style>
