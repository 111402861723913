<template>
    <div :class="param" class="py-4">
        <div class="form-group mb-0 row">
            <div class="offset-md-3 col-md-9">
                <h4 class="fw-normal">{{ question }}</h4>
            </div>
            <label
                for="colFormLabel"
                class="col-md-3 col-form-label col-form-label-lg text-md-right pl-md-0"
                >{{ label }}</label
            >
            <div class="col-md-9">
                <textarea
                    type="text"
                    class="form-control form-control-lg bg-light d-none"
                    @keydown="none($event)"
                    @focus="resize"
                    :name="param"
                    :id="param"
                    :placeholder="placeholder"
                    v-model="hypothesis"
                ></textarea>
                <p class="hypothesis-preview text-white bg-primary fs-5 p-3 rounded-3" v-html="hypothesis"></p>
            </div>
            <div class="offset-md-3 col-md-9">
                <p class="pl-md-2 text-muted">{{ hint }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputHypothesis",
    props: ["question", "placeholder", "label", "hint", "param", "value", "form"],
    data: function() {
        return {
            textarea: null,
            /**
             * Skips the first update to allow the "hypothesis" to be compose
             * the very first time without triggering a $parent.update(...) call.
             *
             *
             */
            skipFirstUpdate: true,
            connenctors: {
                ARE: 'ARE',
                ABOUT: 'ABOUT',
                ABLE: 'IF they were able to',
                COULD: 'THEN they could'
            }
        };
    },

    computed: {
        hypothesis() {
            let formulation = '';

            if(
                this.$store.state.current.opportunity != null &&
                typeof this.$route.params?.form != 'undefined' && 
                typeof this.$store.state.current?.opportunity != 'undefined' &&
                typeof this.$store.state.current?.opportunity?.content?.[ this.$route.params.form ] != 'undefined' &&
                typeof this.$store.state.current?.opportunity?.content?.[ this.$route.params.form ].fields != 'undefined'
            ) {
                let formFields = this.$store.state.current.opportunity.content[ this.$route.params.form ].fields;
                formulation = this.compose(formFields);
            }
            
            return formulation;
        },
    },
    

    watch: {
        hypothesis(newValue) {
            this.resize();
            if(this.skipFirstUpdate) {
                this.skipFirstUpdate = false;
                return;
            }
            
            newValue = newValue.replace(/<[^>]*>/g, '');
            this.$parent.update(this.param, newValue);
        }
    },
	

	methods: {

		update() {
            this.hypothesis = this.compose(this.form);
            this.val = this.hypothesis;
            return this.hypothesis;
        }, 


        resize() {
            setTimeout(()=>{
                this.textarea.style.height = 'auto';
                this.textarea.style.height = (this.textarea.scrollHeight + 2 /** border width (top + bottom) */) + 'px';
            }, 0);
        },
        

        compose(data) {
            let hypothesis = '';
            let audience = data['audience'] || '';
            let emotion  = data['emotion']  || '';
            let problem  = data['problem']  || '';
            let solution = data['solution'] || '';
            let transformation = data['transformation'] || '';

            if( 
                audience.length === 0 || 
                emotion.length === 0 ||
                problem.length === 0 || 
                solution.length === 0 || 
                transformation.length === 0 
            ) {
                hypothesis = '...please complete the sections above';
            } else {
                hypothesis = `${audience} <b class="text-conector">${this.connenctors.ARE}</b> ${emotion} <b class="text-conector">${this.connenctors.ABOUT}</b> ${problem}. <b class="text-conector">${this.connenctors.ABLE}</b> ${solution}, <b class="text-conector">${this.connenctors.COULD}</b> ${transformation}.`;
            }
            
            // hypothesis = encodeURIComponent(hypothesis);
            return hypothesis;
        },

        none(event) {
            event.preventDefault();
            return false;
        }

	},



    created() {
        // this.$watch('hypothesis', function () {
        //     console.log('hypothesis', arguments);
        // }, {deep:true})
    },



    mounted() {
        this.textarea = this.$el.querySelector('textarea');
        this.textarea.addEventListener('paste', (e)=> {
            e.preventDefault();
            return false;
            /**
             * 
             * let textarea = this.$el.querySelector('textarea'); 
             * 
             * if('execCommand' in document) {
             *     let text = e.clipboardData.getData('text/plain');
             *     document.execCommand('insertText', false, "(x) " + text);
             * } else {
             *     navigator.clipboard.readText().then(text => textarea.value = text);
             * }
             */
        });

        this.resize();

        // this.$parent.$on('fieldupdate', (prop, value, data)=>{
        //     prop, value;
        //     this.hypothesis = this.compose(data);
        // });
    },
};
</script>

<style scoped>
.hypothesis-preview,
textarea {
    min-height: 100px;
    resize: none;
    background-color: var(--primary) !important;
    color: #fff !important;
    white-space: normal;
    /* pointer-events: none; */
}

.text-conector {
    color: orange !important;
}

.hypothesis-preview b {
}

/* .textarea-mirror {
    position: absolute;
    z-index: -1;
    width: calc(100% - 30px);
    box-sizing: border-box;
    right: 0;
    left: 0;
    margin-right: 15px;
    margin-left: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    height: auto;
    white-space: normal;
    opacity: 0;
} */
</style>
