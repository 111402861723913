<template>
    <div :class="param" class="py-4">
        <div class="form-group mb-0 row">
            <div class="offset-md-3 col-md-9">
                <h4 class="fw-normal">{{ question }}</h4>
            </div>
            <div class="col-12">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputMultiple",
    props: ["question", "param", "value"],

    data() {
        return {
        };
    },


    methods: {
        update(key, val) {
            this.$parent.progress = true;
            this.$parent.update(key, val);
        },
    },

    mounted: function() {
    },
};
</script>
