<template>
    <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 21.5L12 2.5L23 21.5H1ZM19.53 19.5L12 6.49L4.47 19.5H19.53ZM11 16.5V18.5H13V16.5H11ZM11 10.5H13V14.5H11V10.5Z" fill="black" fill-opacity="0.54"/>
    </svg> -->
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1899 8H20V10H17.91C17.96 10.33 18 10.66 18 11V12H20V14H18V15C18 15.34 17.96 15.67 17.91 16H20V18H17.1899C16.15 19.79 14.22 21 12 21C9.78003 21 7.84998 19.79 6.81006 18H4V16H6.08997C6.04004 15.67 6 15.34 6 15V14H4V12H6V11C6 10.66 6.04004 10.33 6.08997 10H4V8H6.81006C7.26001 7.22 7.88 6.54999 8.62 6.04001L7 4.41L8.41003 3L10.59 5.17001C11.04 5.06 11.51 5 12 5C12.49 5 12.96 5.06 13.42 5.17001L15.59 3L17 4.41L15.37 6.04001C16.12 6.54999 16.74 7.22 17.1899 8ZM16 15V12V11C16 10.78 15.97 10.53 15.9301 10.31L15.83 9.66L15.45 9.01001C15.15 8.48001 14.74 8.04001 14.24 7.70001L13.63 7.28L12.95 7.12C12.63 7.04001 12.3199 7 12 7C11.6899 7 11.37 7.04001 11.0601 7.12L10.3199 7.29999L9.75 7.69C9.26001 8.03 8.83997 8.48001 8.54004 9L8.17004 9.64999L8.06995 10.3C8.03003 10.52 8 10.77 8 11V15C8 15.23 8.03003 15.48 8.06995 15.71L8.17004 16.36L8.54004 17C9.26001 18.23 10.58 19 12 19C13.42 19 14.74 18.24 15.46 17L15.83 16.35L15.9301 15.7C15.97 15.47 16 15.22 16 15ZM10 14H14V16H10V14ZM14 10H10V12H14V10Z" fill="black" fill-opacity="0.54"/>
    </svg>

</template>

<script>
    export default {
        name: 'ProblemsIcon'
    }
</script>