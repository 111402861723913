<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light"
    >
        <option></option>
        <option>business owners</option>
        <option>managers</option>
        <option>directors</option>
        <option>staff</option>
        <option>presidents</option>
        <option>vice predisdents</option>
        <option>entrepreneurs</option>
        <option>founders</option>
        <option>marketers</option>
        <option>developers</option>
        <option>desingers</option>
        <option>professionals</option>
        <option>doctors</option>
        <option>freelancers</option>
        <option>independent contractors</option>
        <option>unemployed</option>
        <option>public servers</option>
        <option>counselors</option>
        <option>advisors</option>
        <option>speakers</option>
        <option>educators</option>
        <option>leaders</option>
        <option>principals</option>
        <option>CEO</option>
        <option>CFO</option>
        <option>VP</option>
        <option>executives</option>
        <option>students</option>
        <option>associates</option>
        <option>representatives</option>
        <option>workers</option>
    </select>
</template>

<script>
export default {
    name: "InputRole",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
