<template>
    <section 
        id="form-problems"
        class="form form-problems"
    >
        <div class="container">
            <form-header
                title="Overview"
                time="7 minutes"
                video="https://www.youtube.com/embed/0Kvw2BPKjz0"
                examples="https://docs.google.com/document/d/1ZQAVGiitVRiKTDrDmoq5bt-6g1NQpUAi_JHo7faSGgY/edit?usp=sharing"
                description="General information about your business and your product."
            ></form-header>

            <div class="row">
                <div class="col-lg-12">
                    <div class="appc-widget">
                        <form @keydown.enter.prevent="" @submit.prevent="">


                            <form-subheader
                                title="About Your Offer"
                                description="Describe your product/service/solution."
                            ></form-subheader>
                            

                            <input-text
                                question="What is your product name?"
                                placeholder=""
                                hint="In a few words, what do you call your product/solution? —25 chrs"
                                label="Product Name"
                                param="product_name"
                                :value="formData['product_name']"
                            ></input-text>


                            <input-textarea
                                question="How would you describe your product?"
                                hint="In one or two sentences, describe your product/service/solution. —200 chrs"
                                label="Product Description"
                                param="product_description"
                                :value="formData['product_description']"
                            >
                            </input-textarea>


                            <input-textarea
                                question="What are the most important features of your product?"
                                hint="Provide a short list of your product/service/solution features. —250 chrs"
                                label="Product Features"
                                param="product_features"
                                :value="formData['product_features']"
                            >
                            </input-textarea>







                            <form-subheader
                                title="About Your Website"
                                description="A few things about the website you will use to promote your product."
                            ></form-subheader>


                            <input-text
                                question="What is your website's name?"
                                hint="A name that you will use to refer to your website/solution. —25 chrs"
                                label="Website Name"
                                param="website_name"
                                :value="formData['website_name']"
                            ></input-text>


                            <input-textarea
                                question="Describe your website/solution"
                                hint="In a short sentence, describe your website/solution. —75"
                                label="Website Description"
                                param="website_description"
                                :value="formData['website_description']"
                            >
                            </input-textarea>


                            <input-text
                                question="Which keywords are relevant to your website/solution?"
                                hint="When searching on Google, which keywords do you expect people to use to find your website/solution? —25 chrs"
                                label="Relevant Keywords"
                                param="keywords"
                                :value="formData['keywords']"
                            ></input-text>





                            <!-- Done -->

                            <input-done
                                question="Mark as complete"
                                placeholder=""
                                hint=""
                                label=""
                                param="done"
                                :value="formData['done']"
                            >
                            </input-done>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormHeader from "@/components/input/InputHeader.vue";
import FormSubheader from "@/components/input/InputSubheader.vue";
import InputText from "@/components/input/InputText.vue";
// import InputFrequency from "@/components/input/InputFrequency.vue";
// import InputSelect from "@/components/input/InputSelect.vue";
// import InputMultiple from "@/components/input/InputMultiple.vue";
// import InputList from "@/components/input/InputList.vue";
// import InputSubheader from "@/components/input/InputSubheader.vue";
import common from "@/components/forms/FormCommon.js";
import InputTextarea from "@/components/input/InputTextarea.vue";
import InputDone from "@/components/input/InputDone.vue";

export default {
    name: "FormFAQ",
    components: {
        FormHeader,
        FormSubheader,
        // InputFrequency,
        // InputSelect,
        InputText,
        // InputMultiple,
        // InputList,
        // InputSubheader,
        InputTextarea,
        InputDone
    },
    
    data() {
        return { ...common.data };
    },

    computed: { ...common.computed },
    
    methods: { ...common.methods },

    mounted: common.mounted
};
</script>
