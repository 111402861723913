<template>
    <div :class="param" class="py-4">
        <div class="form-group mb-0 row">
            <div class="offset-md-3 col-md-9">
                <h4 class="fw-normal">{{ question }}</h4>
            </div>
            <label
                for="colFormLabel"
                class="col-md-3 col-form-label col-form-label-lg text-md-right pl-md-0"
                >{{ label }}</label
            >
            <div class="col-md-9">
                <textarea
                    @keydown="onKeyDown($event)"
                    @change="onKeyUp($event)"
                    type="text"
                    rows="1"
                    class="form-control form-control-lg bg-light"
                    :name="param"
                    :id="param"
                    :placeholder="placeholder"
                    v-model="val"
                ></textarea>
            </div>
            <div class="offset-md-3 col-md-9">
                <p class="pl-md-2 text-muted" v-html="hint"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputTextarea",
    props: ["question", "placeholder", "label", "hint", "param", "value"],
    data: function() {
        return {
            textarea: null
        };
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
                this.resize();
            },
        },

        
	},
    
    
    watch: {
        value() {
            setTimeout(()=>{
                this.resize();
            }, 0);
        }
    },

	methods: {
        onKeyUp() {
        },


        onKeyDown() {
            this.resize();
        },

		resize() {
            this.textarea.style.height = 'auto';
            this.textarea.style.height = (this.textarea.scrollHeight + 2 /** border width (top + bottom) */) + 'px';
		}, 
    },
    

    mounted() {
        this.textarea = this.$el.querySelector('textarea');
        
        setTimeout(()=>{
            this.resize();
        }, 0);
    },
};
</script>

<style scoped>
textarea {
    /* line-height: 20px; */
    min-height: 100px;
    resize: none;
    white-space: normal;
    overflow: hidden;
}
/* 
.textarea-mirror {
    /* position: absolute; * /
    z-index: -1;
    width: calc(100% - 30px);
    box-sizing: border-box;
    right: 0;
    left: 0;
    margin-right: 15px;
    margin-left: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    height: auto;
    /* opacity: 0; * /
    white-space: normal;
} */
</style>
