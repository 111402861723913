<template>
    <select
        :id="param"
        :name="param"
        v-model="val"
        class="form-control form-control-lg bg-light col-6"
    >
        <option></option>
        <option>Date/Time</option>
        <option>Availability</option>
    </select>
</template>

<script>
export default {
    name: "InputScarcity",
    props: ["param", "value"],
    data: function() {
        return {};
    },

    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.$parent.progress = true;
                this.$parent.update(this.param, newValue);
            },
        },
    },
};
</script>
